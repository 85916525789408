import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import * as lpe from '@luxms/lpe';
import { createConstMatrix, createConstVector, matrixSum, vectorSum } from '../data-manip/data-utils';
import { dplus } from './iso8601';


// TODO: remove; implemented in lpe lib: filterit
const sfFilterIt = (ast, ctx, rs) => {
  const array = lpe.eval_lisp(ast[0], ctx, rs);
  const conditionAST = ast[1];
  const result = Array.prototype.filter.call(array, (it, idx) => !!lpe.eval_lisp(conditionAST, [{it, idx}, ctx], rs));
  return result;
};

// TODO: remove; implemented in lpe lib: mapit
const sfMapIt =  (ast, ctx, rs) => {
  const array = lpe.eval_lisp(ast[0], ctx, rs);
  const conditionAST = ast[1];
  const result = Array.prototype.map.call(array, (it, idx) => lpe.eval_lisp(conditionAST, [{it, idx}, ctx], rs));
  return result;
};

const sfSwitch = (ast, ctx, rs) => {
  const value = lpe.eval_lisp(ast[0], ctx, rs);
  for (let entry of ast.slice(1)) {
    if (entry[0] === ':') {
      const valueToCmp = lpe.eval_lisp(entry[1], ctx, rs);
      if (value == valueToCmp) {
        return lpe.eval_lisp(entry[2], ctx, rs);
      }
    } else {
      return lpe.eval_lisp(entry, ctx, rs);           // terminator?
    }
  }
};

const sfCond =  (ast, ctx, rs) => {
  for (let entry of ast) {
    if (entry[0] === ':') {
      const cond = lpe.eval_lisp(entry[1], ctx, rs);
      if (cond) {
        return lpe.eval_lisp(entry[2], ctx, rs);
      }
    } else {
      return lpe.eval_lisp(entry, ctx, rs);           // terminator?
    }
  }
};

const sfGroupAB = (ast, ctx, rs) => {
  const items = lpe.eval_lisp(ast[0], ctx, rs).slice(0);
  const conditionAST = ast[1];
  const result = [];
  while (items.length) {
    let item = items[0];
    let group = [item];                                                         // create new group
    items.splice(0, 1);                                                         // and remove this item
    for (let k = 0; k < items.length; ) {
      const isSameGroup = lpe.eval_lisp(conditionAST, [{a: item, b: items[k]}, ctx], rs);
      if (isSameGroup) {
        group.push(items[k]);
        items.splice(k, 1);
      } else {
        k++;
      }
    }
    result.push(group);
  }
  return result;
};

const collectDescendants = (e) => {
  let result = [];
  for (let child of e.children) {
    result.push(child);
    result = result.concat(collectDescendants(child));
  }
  return result;
};

function collectPaths(e: IEntity): IEntity[] {
  const result = [];
  while (e.parent) result.push((e = e.parent));
  return result;
}

function collectPathsWithMe(e: IEntity): IEntity[] {
  const result = [e];
  while (e.parent) result.push((e = e.parent));
  return result;
}

function last(es: IEntity[], dateInterval): any {
  if (dateInterval == null) return es[es.length - 1];
  if (typeof dateInterval === 'number') return es.slice(Math.max(0, es.length - dateInterval));
  // return es.filter(dateInterval.check)
  return [];
}

const concat = (...es: any): any => flatten(es);
const indexOf = (a: any , b): number => a.indexOf(b);
const exclude = (a: any , b): any => {
  if (a.indexOf(b) > -1) {
    return a.filter((item, i ) => i !== a.indexOf(b));
  } else {
    return a;
  }
};
const slice = (from: number , to: number, arr: any[]): any[] => {
  if (arr.indexOf(from) != -1 && arr.indexOf(to) != -1) {
    return arr.slice(from, to);
  } else {
    return arr;
  }
};
const substr = (str: string , from: number, to: number): string => {
  if (str[from] && str[to]) {
    return str.substr(from, to);
  } else return str;
};
const me_or_ancestors = (es: IEntity[], state: IEntity[] = [], arr: any = [], defaultArr: any = []): IEntity[] => {
  let result = [];
  console.log(state, arr, defaultArr);
  if (arr.length) {
    state.forEach(e => {
      if (arr.includes(Number(e.id)) && !result.filter(el => el.id == e.id).length) {
        result.push(e);
      } else {
        if (e.parent != null && arr.includes(Number(e.parent.id)) && !result.filter(el => el.id == e.parent.id).length) {
          result.push(e.parent);
        }
      }
      if (defaultArr.length) {
        defaultArr.forEach(el => {
          const isAlreadyExist = result.find(e => e.id == String(el));
          const curEl = es.find(e => e.id == String(el));
          if (!isAlreadyExist) {
            result.push(curEl);
          }
        });
      }
    });
  }
  if (!result.length && defaultArr.length) {
    result = es.filter(e => defaultArr.includes(Number(e.id)));
  }
  console.log(result);
  return result;
};
const children = (es: IEntity[]): IEntity[] => concat.apply(null, (Array.isArray(es) ? es : [es]).map(e => e.children));
const parents = (es: IEntity[]): IEntity[] => uniq(es.map(e => e.parent).filter(e => e != null));
const descendants = (es: IEntity[]): IEntity[] => uniq(Array.prototype.concat.apply([], es.map(collectDescendants)));
const me_and_descendants = (es: IEntity[]): IEntity[] => uniq(Array.prototype.concat.apply(es, es.map(collectDescendants)));
const siblings = (es: IEntity[]): IEntity[] => children(parents(Array.isArray(es) ? es : [es]));
const get_tag = (e: any, tagAxis) => e.getTagByGroupId(tagAxis) ? e.getTagByGroupId(tagAxis).id : '';
const with_tag = (es: ITaggedEntity[], tagId: string): ITaggedEntity[] => es.filter(e => !!e.getTag(String(tagId)));
const one_with_tag = (es: ITaggedEntity[], tagId: string): ITaggedEntity => es.find(e => !!e.getTag(String(tagId))) || null;
const pick_with_tags = (es: ITaggedEntity[], ...tagIds: string[]): ITaggedEntity[] => tagIds.map(tag => one_with_tag(es, tag)).filter(t => t !== null);

const fufelLib = {
  'sf:filterIt': sfFilterIt,
  'sf:mapIt': sfMapIt,
  'sf:switch': sfSwitch,
  'sf:cond': sfCond,
  'sf:groupAB': sfGroupAB,
  last,
  children,
  parents,
  exclude,
  indexOf,
  me_or_ancestors,
  substr,
  descendants,
  siblings,
  concat,
  get_tag,
  'paths': (es: IEntity[]): IEntity[] => uniq(Array.prototype.concat.apply([], es.map(collectPaths))),
  'me_and_paths': (es: IEntity[]) => uniq(Array.prototype.concat.apply([], es.map(collectPathsWithMe))),
  with_tag,
  one_with_tag,
  pick_with_tags,
  createConstVector,
  createConstMatrix,
  vectorSum,
  matrixSum,
  me_and_descendants,
  'avg': (vec) => {
    const filtered = vec.filter(v => v != null);
    return vectorSum(filtered) / filtered.length;
  },
  'startsWith': (str, template) => str.startsWith(template),
  now: () => new Date().toJSON(),
  dplus,
  'sf:lpe': (ast, ctx, rs) => {
    const lpeString = lpe.eval_lisp(ast[0], ctx, rs);
    const result = lpeRun(lpeString, ctx);
    return result;
  },
  'sf:when': (ast, ctx, rs) => {
    for (let i = 0; i < ast.length; i += 2) {
      let cond = lpe.eval_lisp(ast[i], ctx, rs);
      if (!ast[i + 1]) return cond;                                                                 // last means "else"
      if (cond) return lpe.eval_lisp(ast[i + 1], ctx, rs);
    }
    return undefined;
  },
};

const cachedBuilds = {};

export function lpeRun(lpeCode: any, ctx: any): any {
  if (typeof lpeCode === 'string') {
    if (lpeCode in cachedBuilds) {
      lpeCode = cachedBuilds[lpeCode];
    } else {
      lpeCode = cachedBuilds[lpeCode] = lpe.parse(lpeCode);
    }
  }

  ctx = [ctx || {}, fufelLib];

  const result = lpe.eval_lisp(lpeCode, ctx);
  return result;
}
