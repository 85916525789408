exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.DatasetImage,.DatasetImageBucket{position:relative;width:10rem;height:10rem;top:auto;left:auto}.DatasetImage.loading .WpLoadingIcon{position:absolute;top:.1rem;width:95%}.DatasetImage{background-color:#f0f0f0}.DatasetImage.error{background:repeating-linear-gradient(-45deg, #ffcdd2, #ffcdd2 10px, #ffdede 10px, #ffdede 20px);text-align:left;padding:.5rem;font-size:.8rem;line-height:1rem}.DatasetImage__NoImageIcon{position:absolute;left:50%;top:50%;width:100%;height:100%;transform:translate(-50%, -50%)}.DatasetImageBucket{cursor:pointer;z-index:1}.DatasetImageBucket__Drop{position:absolute;left:0;top:0;right:0;bottom:0;background:#f0f0f0;z-index:1;display:flex;align-items:center;justify-content:center;pointer-events:none;opacity:0;transition:opacity .2s linear}.DatasetImageBucket.drop .DatasetImageBucket__Drop{opacity:100%}.DatasetImageBucket__DropIcon{width:50%}.DatasetImageBucket__Canvas{position:absolute;width:10rem;height:10rem;left:0;top:0;pointer-events:none}.DatasetImageBucket__File{display:none !important}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};