exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.ExpandableSearch{position:fixed;display:flex;z-index:9;margin:0;color:#1d3557;height:1px;width:100%}.ExpandableSearch.expanded{width:calc(100% - 10rem);max-width:880px;height:3rem;background:#fff}.ExpandableSearch__SearchTerm{background:rgba(0,0,0,0);box-shadow:none;border:none;padding:0;margin:0 0 0 1rem;width:100%;visibility:hidden}.ExpandableSearch.expanded .ExpandableSearch__SearchTerm{position:relative;visibility:visible;top:6px;display:block;border:none;padding:0 1rem;border-bottom:solid 2px #1d3557;transition:all .3s cubic-bezier(0.64, 0.09, 0.08, 1);background:#fff;height:3rem}.ExpandableSearch.expanded .ExpandableSearch__SearchTerm:focus,.ExpandableSearch.expanded .ExpandableSearch__SearchTerm:valid{box-shadow:none;outline:none;background-position:0 0}.ExpandableSearch.expanded .ExpandableSearch__SearchTerm:focus::-webkit-input-placeholder,.ExpandableSearch.expanded .ExpandableSearch__SearchTerm:valid::-webkit-input-placeholder{color:#41c7ff;font-size:11px;transform:translate(0, -20px);visibility:visible !important}.ExpandableSearch__Icon{cursor:pointer;position:absolute;left:-50px;top:2px;display:flex;flex-direction:column;align-items:center;width:50px;height:50px}.ExpandableSearch__Icon>.title{font-size:9px;letter-spacing:.1px;font-weight:600;text-transform:uppercase;margin-top:3rem}.ExpandableSearch__Icon{color:#333}.ExpandableSearch__Icon:hover{color:#000}.ExpandableSearch.expanded .ExpandableSearch__Icon{color:#1d3557}.ExpandableSearch__Icon svg{fill:#333 !important;stroke:#333 !important}.ExpandableSearch__Icon:hover svg{fill:#000 !important;stroke:#000 !important}.ExpandableSearch.expanded .ExpandableSearch__Icon svg{fill:#1d3557 !important;stroke:#1d3557 !important}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};