import React from 'react';
import cn from 'classnames';
import { mouseWatcher } from '../../../libs/MouseWatcher';
import $ from 'jquery';
import './ContextMenu.scss';

export interface IMenuItem {
  title: string;
  action?: (arg: this) => any;
  render?: () => any;
}

type IPlacement = 'bottom' | 'bottom-end' | 'bottom-start';

interface IContextMenuOptions {
  arrow?: boolean;
  placement?: IPlacement;
}


function _checkElement(el: any): boolean {
  if (!el) {
    return false;
  }
  if (typeof SVGElement === 'function' && (el instanceof SVGElement)) {
    return true;
  }
  if (el instanceof HTMLElement) {
    return true;
  }
  if ('tagName' in el) {
    return true;
  }

  return false;
}

interface IContextMenuState {
  visible: boolean;
  left: number;
  top: number;
  arrow: boolean;
  placement: IPlacement;
  items: IMenuItem[];
}

export class ContextMenu extends React.PureComponent<{}, IContextMenuState> {
  public state: IContextMenuState = {
    visible: false,
    left: 0,
    top: 0,
    arrow: false,
    placement: 'bottom-end',
    items: [],
  };
  private _$container = null;
  private _$el: JQuery = null;
  private _elPos = null;                                                        // element on which menu is displayed

  public constructor(props) {
    super(props);
    console.assert(ContextMenu._instance === null);
    ContextMenu._instance = this;

    $('body').on('keyup', (e) => {
      if (!this.state.visible) return;
      if (e.keyCode == /* ESC */ 27) {
        this._hide();
      }
    }).on('mousedown touchstart click', (e) => {
      // if (this._$el && $.contains(this._$el[0], e.target)) {
      if (this._$el && this._$el[0] === e.target) {
        // debugger;
        return false;
      }

      if (this.state.visible) {
        if (!this._$container.has($(e.target)).length) {
          this._hide();
        }
      }

      return true;
    });
  }

  private _attachToElement(el) {
    if (!_checkElement(el)) {
      console.error('drilldown: attach to element: el is not valid HTML Element1', el);
      return;
    }
    this._$el = $(el);

    const $c: JQuery = this._$el.closest('svg');                                  // elements in svg (charts) change often (hover...)
    if ($c.length) {                                                              // so if we attach to element, we will loose it
      this._$el = $c;
    }
    this._elPos = (this._$el[0].getBoundingClientRect) ? this._$el[0].getBoundingClientRect() : this._$el.position();

    window.setInterval(() => {
      if (!this._$el) return;
      const newPos: any = (this._$el[0].getBoundingClientRect) ? this._$el[0].getBoundingClientRect() : this._$el.position();
      if (this._elPos.left !== newPos.left || this._elPos.top !== newPos.top || this._elPos.height !== newPos.height || this._elPos.width !== newPos.width) {
        this._hide();
      }
    }, 400);
  }

  private _onSetupContainer = (container: HTMLElement | null) => {
    this._$container = container ? $(container) : null;

    if (container) {
      this._fixPosition();
    }
  }

  public componentDidUpdate(prevProps, prevState, snapshot?): void {
    this._fixPosition();
  }

  private _fixPosition() {
    if (!this._$container) {
      return;
    }

    const $wrapper: JQuery = this._$container.find('.ContextMenu__Wrapper');
    const $menuBody: JQuery = this._$container.find('.ContextMenu__Body');
    const $body = $('body');
    const width: number = $menuBody.width(), screenWidth = $body.width();
    const height: number = $menuBody.height(), screenHeight = $body.height();
    const {left, top, placement} = this.state;

    let mustBeAtLeft = 0, mustBeAtRight = 0;
    if (placement === 'bottom')             { mustBeAtLeft = width / 2; mustBeAtRight = width / 2; }
    else if (placement === 'bottom-end')    { mustBeAtLeft = width;     mustBeAtRight = 0;         }
    else if (placement === 'bottom-start')  { mustBeAtLeft = 0;         mustBeAtRight = width;     }
    if (left - mustBeAtLeft < 0) {
      $wrapper.css('left', (mustBeAtLeft - left) + 'px');
    } else if (left + mustBeAtRight >= screenWidth) {
      $wrapper.css('left', (screenWidth - mustBeAtRight - left - 10) + 'px');
    } else {
      $wrapper.css('left', '0');
    }
    $wrapper.css({width, height});
    let isTop: boolean = this.state.top + height + 20 > screenHeight;

    if (isTop) {
      this._$container.removeClass('placeBottom').addClass('placeTop');
    } else {
      this._$container.removeClass('placeTop').addClass('placeBottom');
    }
  }

  private _onClickItem = async (item) => {
    if (item.action) {
      try {
       await item.action(item);
      } catch (err) {
        console.error('Item action error', err);
        debugger;
      }
    }
    this._hide();
  }

  private _show(eventRaw, items: IMenuItem[], options: IContextMenuOptions = {}) {
    items = items.filter(Boolean);
    if (!eventRaw || items.length === 0) {
      this._hide();
      return;
    }
    const isEchartsEvent = eventRaw.hasOwnProperty('event');
    const event = isEchartsEvent ? eventRaw.event.event : eventRaw;
    let clickElement = event.target || (event.currentTarget && event.currentTarget.graphic && event.currentTarget.graphic.element);
    let left = mouseWatcher.getMouseX();
    let top = mouseWatcher.getMouseY() + 5;
    if (event.correctCoords && event.correctCoords.element) {   // special hack for free clicked and finding nearest point
      clickElement = event.correctCoords.element;
      const rect = event.correctCoords.element.getBoundingClientRect();
      if (!isPointInsideRect(left, top, rect)) {         // some point outside element may need to correct
        const fixed_left: number = rect.left + 5;
        const fixed_top: number = rect.top + 5;
        const dist2: number = (fixed_left - left) * (fixed_left - left) + (fixed_top - top) * (fixed_top - top);
        if (!isPointInsideRect(left, top, rect) && dist2 > 1600) {   // radius: 40px
          console.log('Click was too far from nearest point: ', Math.round(Math.sqrt(dist2)));
          this._hide();
          return;
        }
        left = fixed_left;
        top = fixed_top;
      }
    } else if (event.correctCoords && ('x' in event.correctCoords)) {
      left = event.correctCoords.x;
      top = event.correctCoords.y;
    }

    this._attachToElement(clickElement);

    this.setState({
      visible: true,
      left, top, items,
      arrow: !!options.arrow,
      placement: options.placement || 'bottom-end',
    });
  }

  private _hide() {
    this._$el = null;
    this._elPos = null;
    this.setState({
      visible: false,
      left: 0,
      top: 0,
      arrow: false,
      placement: 'bottom-end',
      items: [],
    });
  }

  public render() {
    const {visible, left, top, arrow, placement, items} = this.state;

    if (!visible) {
      return null;
    }

    return (
      <section className={cn('ContextMenu', {arrow}, placement)}
           ref={this._onSetupContainer}
           role="tooltip"
           style={{left, top}}>
        {!!arrow && <div className="ContextMenu__Arrow"/>}

        <div className="ContextMenu__Wrapper">
          <article className="ContextMenu__Body">
            <ul className="ContextMenu__Items" role="menu" aria-labelledby="dropdownMenu">
              {items.map(item =>
              <li key={item.title} tabIndex={1} data-bind="event: {mousedown: action, touchstart: action}">
                {item.render ?
                item.render() :
                <a data-property="parameter"
                   tabIndex={1}
                   onClick={() => this._onClickItem(item)}
                   href={void(0)}>{item.title}</a>}
              </li>)}
            </ul>
          </article>
        </div>
      </section>);
  }

  private static _instance: ContextMenu = null;

  public static hide() {
    ContextMenu._instance._hide();
  }

  public static show(event, items: IMenuItem[], options?: IContextMenuOptions) {
    ContextMenu._instance._show(event, items, options);
  }
}

function isPointInsideRect(x: number, y: number, rect: any): boolean {
  return (x >= rect.left - 5) && (x <= rect.right + 5) &&
    (y >= rect.top - 5) && (y <= rect.bottom + 5);
}


export default ContextMenu;
