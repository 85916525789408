/**
 *
 * BIPath entry point
 *
 *
 */

import uniq from 'lodash/uniq';
declare const bipath_peg: any;

function collectDescendants(e) {
  return Array.prototype.concat.apply(e.children, e.children.map(collectDescendants));
}

function collectPaths(e: IEntity): IEntity[] {
  const result = [];
  while (e.parent) result.push((e = e.parent));
  return result;
}

function collectPathsWithMe(e: IEntity): IEntity[] {
  const result = [e];
  while (e.parent) result.push((e = e.parent));
  return result;
}

const FUNCS: any = {
  last: (es: IEntity[], dateInterval): IEntity[] => es.filter(dateInterval.check),
  children: (es: IEntity[]): IEntity[] => Array.prototype.concat.apply([], es.map(e => e.children)),
  descendants: (es: IEntity[]): IEntity[] => uniq(Array.prototype.concat.apply([], es.map(collectDescendants))),
  parents: (es: IEntity[]): IEntity[] => uniq(es.map(e => e.parent).filter(e => e != null)),
  paths: (es: IEntity[]): IEntity[] => uniq(Array.prototype.concat.apply([], es.map(collectPaths))),
  me_and_paths: (es: IEntity[]) => uniq(Array.prototype.concat.apply([], es.map(collectPathsWithMe))),
  with_tag: (es: ITaggedEntity[], tagId: string): ITaggedEntity[] => es.filter(e => !!e.getTag(String(tagId))),
};


export function evalBiPathExpression<T>(expr: string, es: T[], stateEs: T[]): T[] {
  const f: any = bipath_peg.parse(expr);
  const funcs = {
    ...FUNCS,
    state: function (es: IEntity[]) {
      return stateEs;
    },
    selected: function (es: IEntity[]) {
      return stateEs;
    },
  };

  return f(es, funcs);
}
