import { BaseService, createObjectsCache, repo, srv } from '@luxms/bi-core';
import DimensionsService = srv.koob.DimensionsService;
import IRawDimension = repo.koob.IRawDimension;
import { cloneDeep } from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import { koobDataRequest3 } from './KoobDataService';

interface IKoobDimensionsMemberModel {
  readonly loading: boolean;
  readonly error: string;
  readonly dimensions: IRawDimensionMember[];
}

interface IRawDimensionMember extends IRawDimension {
  readonly members?: { id: number, title: string }[];
}

export class KoobDimensionsMemberService extends BaseService<IKoobDimensionsMemberModel> {
  private readonly _dimensionsService: DimensionsService;
  private readonly _source_ident: string;
  private readonly _cube_name: string;

  private _cancelToken: CancelTokenSource | null = null;

  protected constructor(source_ident: string, cube_name: string) {
    super({loading: true, error: null, dimensions: []});
    this._source_ident = source_ident;
    this._cube_name = cube_name;

    this._dimensionsService = srv.koob.DimensionsService.createInstance(source_ident, cube_name);
    this._dimensionsService.subscribeUpdatesAndNotify(this._onDimensionsServiceUpdated);
  }

  private _onDimensionsServiceUpdated = (model: any): void => {
    if (model.loading || model.error) {
      this._updateModel({loading: model.loading, error: model.error});
      return;
    }
    const dimensions = cloneDeep(model.filter((m) => m?.id));
    this._updateModel({loading: false, error: null, dimensions});
  }

  public loadMember = async (column: string, onlyDefault: boolean): Promise<IRawDimensionMember> => {
    await this.whenReady();
    const model = this._model;
    const dimension: any = cloneDeep(this._model.dimensions.find((m) => m.name === column));
    if (!dimension) return null;
    if (dimension?.members) return dimension;
    this._cancelToken = axios.CancelToken.source();

    if (Array.isArray(dimension.config?.members?.default)) dimension.members = dimension.config.members.default;
    else if (dimension?.config?.members?.default?.columns) {

      const column = dimension.config.members.default.columns;
      const filters = dimension.config.members.default?.filters || {};
      const sort = dimension.config.members.default?.sort;
      const koob = dimension.config.members.default?.with || `${this._source_ident}.${this._cube_name}`;

      const members = await koobDataRequest3(koob, column, [], filters, {
        sort,
        cancelToken: this._cancelToken.token
      }) ?? [];
      dimension.members = members;
    } else if (!onlyDefault) {

      const koob = `${this._source_ident}.${this._cube_name}`;
      const columns = [`${column}:id`, `${column}:title`];
      const members = await koobDataRequest3(koob, columns, [], {cancelToken: this._cancelToken.token}) ?? [];

      dimension.members = members;
    }

    this._cancelToken = null;
    const idx = model.dimensions.findIndex((d) => d.name === column);
    model.dimensions[idx] = dimension;

    this._updateModel({dimensions: model.dimensions});
    return dimension;
  }

  protected _dispose() {
    this._dimensionsService.unsubscribe(this._onDimensionsServiceUpdated);
    KoobDimensionsMemberService._cache.remove(this._source_ident, this._cube_name);
    super._dispose();
  }

  private static _cache = createObjectsCache((source_ident: string, cube_name: string) => new KoobDimensionsMemberService(source_ident, cube_name), '__koobDimensionsMemberService');
  static createInstance: (source_ident: string, cube_name: string) => KoobDimensionsMemberService = KoobDimensionsMemberService._cache.get;
}