import React, { useEffect, useState } from 'react';
import memoize from 'lodash/memoize';

const loadIcon = memoize(async (path: string) => {
  const response = await fetch(path);
  return response.text();
});

const SVGIcon = (props: {path: string, className?: string, [id: string]: any}) => {
  const {path} = props;
  const [content, setContent] = useState<any>(null);

  const makeSVG = (content) => {
    const el = document.createElement('div' );
    el.innerHTML = content;
    const svg = Array.from(el.children).find(e => e.tagName === 'svg');
    if (!svg) return;
    let svgProps: any = {...props};
    Array.from(svg.attributes).forEach(({name, value}) => svgProps[name] = value);                // TODO: исправлять атрибуты в React camelCase
    svgProps.dangerouslySetInnerHTML = {__html: svg.innerHTML};                                   // и style распарсить в объект
    // setContent(React.createElement('svg', svgProps));
    setContent(svgProps);
  };

  useEffect(() => {
    if (path.indexOf('<') != -1) {
      makeSVG(path);
    } else {
      loadIcon(path).then(content => {
        makeSVG(content);
      });
    }
  }, [path]);

  return content ? <svg data-path={path.replace(/^.*\/\.\.\//, '')} {...content} {...props}/> : null;
};

export default SVGIcon;
