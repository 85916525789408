/**
 * Этот сервис всегда будет готов
 */

import { BaseService } from '@luxms/bi-core';

const MODEL: any = [];
MODEL.loading = false;
MODEL.error = null;

class NullService extends BaseService<any> {
  public constructor(...args: any) {
    super(MODEL);
  }
}

export default NullService;
