exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.OpenModalContainer{position:fixed;top:0;left:0;height:100%;width:100%;z-index:19;display:flex;align-items:center;justify-content:center}.OpenModalContainer .OpenModalContainer__Content{position:relative;z-index:20;max-width:95%;max-height:95%}.OpenModalContainer .OpenModalContainer_Wrapper-Hidden{position:absolute;top:0;left:0;height:100%;width:100%;background-color:rgba(0,0,0,.45);z-index:19}.OpenModalContainer .OpenModalContainer_Wrapper-Hidden.hidden{display:none}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};