import { BaseService, createSingleton } from '@luxms/bi-core';

export enum AlertType {
  NEWS = 'news',
  INFO = 'info',
  INFO_IMPORTANT = 'info_important',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}


export interface IAlert {
  type: AlertType;
  title?: string;
  description: string;
  stackId?: string;
  onPressClose?: () => void;
  created?: number;
}


export interface IAlertsVM {
  loading: boolean;
  error: string | null;
  alertItems: IAlert[];
}

const ALERT_VISIBLE_TIME = 4000;


export class AlertsVC extends BaseService<IAlertsVM> {
  private _timerId: number;
  private _timeout: number = ALERT_VISIBLE_TIME;

  private constructor() {
    super({
      loading: false,
      error: null,
      alertItems: [],
    });
    this._timerId = window.setInterval(this._onTimer, 500);
  }

  protected _dispose() {
    window.clearInterval(this._timerId);
    this._timerId = -1;
    super._dispose();
  }

  private _removeAlert(alert: IAlert): void {
    let alertItems = this._model.alertItems.slice(0);
    const idx = alertItems.indexOf(alert);
    if (idx !== -1) {
      alertItems.splice(idx, 1);
      this._updateModel({alertItems});
    }
  }

  private _onTimer = () => {
    const now = new Date().valueOf();
    const alertItems = this._model.alertItems.filter(alertItem => now < alertItem.created + this._timeout);
    if (alertItems.length === this._model.alertItems.length) {
      return;
    }
    this._updateModel({alertItems});
  };

  public pushAlert(rawAlert: IAlert, timeout?: number): void {
    if(timeout) {
      this._timeout = timeout;
    } else {
      this._timeout = ALERT_VISIBLE_TIME;
    }
    let alertItems = this._model.alertItems.slice(0);
    const alert: IAlert = {
      ...rawAlert,
      onPressClose: () => this._removeAlert(alert),
      created: new Date().valueOf(),
    };
    alertItems.push(alert);
    this._updateModel({alertItems});
  }

  public pushNewsAlert(description: string, title?: string, timeout?: number) {
    this.pushAlert({type: AlertType.NEWS, description, title}, timeout);
  }

  public pushInfoAlert(description: string, title?: string, timeout?: number) {
    this.pushAlert({type: AlertType.INFO, description, title}, timeout);
  }

  public pushSuccessAlert(description: string, title?: string, timeout?: number) {
    this.pushAlert({type: AlertType.SUCCESS, description, title}, timeout);
  }

  public pushDangerAlert(description: string, title?: string, timeout?: number) {
    this.pushAlert({type: AlertType.DANGER, description, title}, timeout);
  }

  public pushWarningAlert(description: string, title?: string, timeout?: number) {
    this.pushAlert({type: AlertType.WARNING, description, title}, timeout);
  }

  public static getInstance = createSingleton<AlertsVC>(() => new AlertsVC(), '__alertsVC');

  public static pushAlert(rawAlert: IAlert): void {
    this.getInstance().pushAlert(rawAlert);
  }

  public static pushNewsAlert(description: string, title?: string, timeout?: number) {
    this.getInstance().pushNewsAlert(description, title, timeout);
  }

  public static pushInfoAlert(description: string, title?: string, timeout?: number) {
    this.getInstance().pushInfoAlert(description, title, timeout);
  }

  public static pushSuccessAlert(description: string, title?: string, timeout?: number) {
    this.getInstance().pushSuccessAlert(description, title, timeout);
  }

  public static pushDangerAlert(description: string, title?: string, timeout?: number) {
    this.getInstance().pushDangerAlert(description, title, timeout);
  }

  public static pushWarningAlert(description: string, title?: string, timeout?: number) {
    this.getInstance().pushWarningAlert(description, title, timeout);
  }
}

export default AlertsVC;
