import { BaseService, AppConfig, createObjectsCache, extractErrorMessage } from '@luxms/bi-core';
import { $eid, $eidx } from '../../libs/imdas/list';
import axios from 'axios';
import { IKoobDimension, IKoobMeasure } from '../../defs/bi';


//
// INTERFACES
//

export interface IKoob {
  id: string;
  source_ident?: string;
  name?: string;
  title: string;
  sql: string;
  dimensions: IKoobDimension[];
  measures: IKoobMeasure[];
}


export interface IKoobModel {
  id: string;
  config: any;
  loading?: boolean;
  error?: string;
  dimensions: IKoobDimension[];
  measures: IKoobMeasure[];
}

/**
 * TODO: merge with the src/services/adm/CubeService
 */
export class KoobService extends BaseService<IKoobModel> {
  public readonly id: string;
  private _detailedEntities: { [entityId: string]: string[] } = {};
  private _isMainLoading: boolean = true;

  private constructor(koobId: string) {
    super({
      id: koobId,
      loading: true,
      error: null,
      dimensions: [],
      measures: [],
      config: {},
    });
    this.id = koobId;
    this._init();
  }

  public async loadEntityDetails(entityId: string) {
    let fullId: string = entityId;
    if (!fullId.startsWith(this.id + '.')) {
      fullId = this.id + '.' + fullId;
    }
    entityId = fullId.split('.').slice(-1)[0];

    if (fullId in this._detailedEntities) return;                           // marked as loading or exists
    this._detailedEntities[fullId] = null;                                                          // bad loading mark
    this._detailedEntities[entityId] = null;                                                        // bad loading mark
    const url = AppConfig.fixRequestUrl(`/api/v3/koob/${fullId}`);

    this._updateWithLoading();

    const request = await fetch(url, {credentials: 'include'});
    const result = await request.json();

    if (result.values && Array.isArray(result.values)) {
      result.members = result.values.map(value => ({id: value, title: value}));
    }


    this._detailedEntities[fullId] = result;                                                        // save detailed entity to cache
    this._detailedEntities[entityId] = result;                                                      // save detailed entity to cache

    let {dimensions, measures} = this._model;

    let idx = $eidx(dimensions, entityId);                                      // update if necessary

    if (idx !== -1) {
      dimensions = dimensions.slice(0);
      dimensions[idx] = result;
    }
    idx = $eidx(measures, entityId);
    if (idx !== -1) {
      measures = measures.slice(0);
      measures[idx] = result;
    }

    const loading = this._isDetailsLoading() || this._isMainLoading;
    this._updateModel({loading, dimensions, measures});
  }

  private _isDetailsLoading(): boolean {
    for (let entityId in this._detailedEntities) {
      if (this._detailedEntities[entityId] === null) {                          // null is loading mark
        return true;
      }
    }
    return false;
  }

  private async _init() {
    try {
      const url = AppConfig.fixRequestUrl(`/api/v3/koob/${this.id}`);
      const result: any = (await axios.get(url)).data;
      if (!this._model) {                                                         // disposed!
        return;
      }
      result.dimensions.forEach((dimension, idx) => {
        if (dimension.id.match(/^\w+\.\w+\.(\w+)$/)) {                                        // иногда приходят в формате x.y.ID
          dimension.id = RegExp.$1;
        }
        dimension.axisId = dimension.id;
        if (this._detailedEntities[dimension.id]) {
          result.dimensions[idx] = this._detailedEntities[dimension.id];
        }
      });
      result.measures.forEach((measure, idx) => {
        if (this._detailedEntities[measure.id]) {
          result.measures[idx] = this._detailedEntities[measure.id];
        }
      });
      this._isMainLoading = false;
      const loading = !!this._isDetailsLoading() || this._isMainLoading;

      this._updateModel({
        error: null,
        loading,
        config: result?.config ?? {},
        dimensions: result.dimensions,
        measures: result.measures,
      });
    } catch (err) {
      console.error(err);
      this._updateModel({
        error: extractErrorMessage(err),
        loading: false,
        dimensions: [],
        measures: [],
      });
    }
  }

  protected _dispose() {
    KoobService._cache.remove(this.id);
    super._dispose();
  }

  private static _cache = createObjectsCache(id => new KoobService(String(id)), '__koobServices');

  public static createInstance: (id: string | number) => KoobService = KoobService._cache.get;
}

