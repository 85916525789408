import React from 'react';
import {ResourcesService} from '@luxms/bi-core/dist/services/ds';
import {AppConfig} from '@luxms/bi-core';
import AppLogo from '@luxms/bi-face/AppLogo';

const skin = require('../../../../src/skins/skin.json');

interface ILogo {
  urlImg: string;
}

export class Logo extends React.Component<any, ILogo> {
  private readonly _resourcesService: ResourcesService;
  private readonly _appConfig: AppConfig;

  public state: ILogo;

  public constructor(props) {
    super(props);
    this._resourcesService = ResourcesService.createInstance('ds_res');
    this._appConfig = AppConfig.getInstance();
    this.state = {urlImg: '../assets/images/logo.png'};
  }

  public componentDidMount() {
    this._resourcesService.subscribeUpdatesAndNotify(this._onUpdateModel);
  }

  public componentWillUnmount() {
    this._resourcesService.unsubscribe(this._onUpdateModel);
  }

  private _onUpdateModel = (): void => {
    const model = this._resourcesService.getModel();
    if (model.loading || model.error) return;

    let urlImg = this.state.urlImg;
    if (model.find(r => r.alt_id === 'thumbnail.svg')) urlImg = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.svg');
    else if (model.find(r => r.alt_id === 'thumbnail.png')) urlImg = AppConfig.fixRequestUrl('/srv/resources/ds_res/thumbnail.png');

    this.setState({urlImg});
  }

  public render() {
    const {urlImg} = this.state;
    const title = AppConfig.getInstance().getModel()?.projectTitle ?? skin?.appName ?? 'Luxms BI';

    return (
      <AppLogo icon={urlImg} altImage={title}>
        <AppLogo.Text major>{title}</AppLogo.Text>
      </AppLogo>
    );
  }
}