import React from 'react';
import InternalComponentVC from '../../view-controllers/InternalComponentVC';
import useService from '../useService';
import { srv, UrlState } from '@luxms/bi-core';
import NullService from '../../services/NullService';
import ResourceLocatorService from '../../services/ResourceLocatorService';

interface ILoadFromResourcesProps {
  path: string;
  children?: any;
  [key: string]: any;
}


// export class LoadFromResources extends React.Component<ILoadFromResourcesProps> {
//   public state: {
//     error: string;
//     loading: boolean;
//     Component: any;
//   } = {
//     error: null,
//     loading: true,
//     Component: null
//   };
//   private loadResourceService: InternalComponentVC;
//
//   public constructor(props: any) {
//     super(props);
//   }
//
//   private onIntServiceUpd = (module) => {
//     if (module.loading || module.error) {
//       this.setState({Component: null, error: null, loading: false});
//     }
//     this.setState({Component: module.Component, error: module.error, loading: false});
//   }
//
//   public componentDidMount() {
//     const {path} = this.props;
//     const pathSplitted = path.split('/');
//     const schema_name = pathSplitted[0] || 'ds_res';
//     const file = pathSplitted.slice(1).join('/');
//     this.loadResourceService = new InternalComponentVC(schema_name, file);
//     this.loadResourceService.subscribeUpdatesAndNotify(this.onIntServiceUpd);
//   }
//
//   public componentWillUnmount() {
//     this.loadResourceService.unsubscribe(this.onIntServiceUpd);
//   }
//
//   public render() {
//     const {children} = this.props;
//     const {error, loading, Component} = this.state;
//     if (loading) return null;
//     let module = Component && !error ? <Component {...this.props}/> : children;
//
//     return module;
//   }
// }

const LoadFromResources = (props: ILoadFromResourcesProps) => {
  const dsResource = useService<ResourceLocatorService>(ResourceLocatorService, null, props.path);
  const dsResResource = useService<ResourceLocatorService>(ResourceLocatorService, 'ds_res', props.path);

  let schema_name: string = null;

  if (dsResource.resource) {
    schema_name = dsResource.schema_name;
  } else if (dsResResource) {
    schema_name = dsResResource.schema_name;
  }

  const internalComponent = schema_name ?
    useService<InternalComponentVC>(InternalComponentVC, schema_name, props.path) :
    useService<any>(NullService);

  const error = dsResResource.error || dsResResource.error || internalComponent.error;
  const loading = dsResResource.loading || dsResResource.loading || internalComponent.loading;

  // ошибки игнорим же?
  if (loading) return null;

  return internalComponent.Component ? React.createElement(internalComponent.Component, props) : props.children;
};

export default LoadFromResources;
