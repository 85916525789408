import React from 'react';
import {IRootSegment} from '../../plugins/plugins-model';
import {Menu} from '@luxms/bi-face';
import {Logo} from '../../../srx/views/app/Menu/Logo';


interface IRootMenuProp {
  tabs: IRootSegment[];
  activeTabIndex: number | null;
}

export class RootMenu extends React.Component<IRootMenuProp, any> {
  public constructor(props) {
    super(props);
  }

  private _renderMenuItem = (item: IRootSegment, idx: number): any => {
    const {activeTabIndex} = this.props;

    let icon = item?.getIcon?.() ?? null;
    if (icon) icon = React.createElement(icon, null);
    return (
      <Menu.Item active={idx === activeTabIndex} icon={icon} key={item.key} title={item.title} href={item.url}>
        {item.title}
      </Menu.Item>
    );
  }

  public render() {
    const {tabs = [], activeTabIndex = null} = this.props;
    if (activeTabIndex === null) return null;
    return (
      <Menu className="Root_Menu" onExpanded={() => null} onCompact={() => null} onHidden={() => null}>
        <Menu.Header>
          <a href="/#/"><Logo/></a>
        </Menu.Header>
        <div className="Root_Menu_Content BIScroll">
          <Menu.List>{tabs.map(this._renderMenuItem)}</Menu.List>
        </div>
        <Menu.Footer>
          <Menu.Toolkit>
            <Menu.ToggleControls>
              <Menu.Control mark="collapseMenu"/>
              <Menu.Control mark="expandMenu"/>
            </Menu.ToggleControls>
          </Menu.Toolkit>
        </Menu.Footer>
      </Menu>
    );
  }
}