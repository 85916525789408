import React from 'react';
import {Header, AccountTool} from '@luxms/bi-face';
import {Logo} from '../../../srx/views/app/Menu/Logo';
import {BreadcrumbControl} from './BreadcrumbControl';
import {AuthenticationService, UrlState} from '@luxms/bi-core';
import ActionList from '../../../srx/components/action/ActionList';
import {lang} from '../../utils/utils';
import {MainToolbar__EditModeButton, MainToolbar__ThemeSwitchButton, HrefButton} from '../panels/MainToolbar/MainToolbar';
import {RootSearch} from './RootSearch';
import LoadFromResources from '../components/LoadFromResources';

const skin = require('../../skins/skin.json');

interface IRootHeaderState {
  accountVisible: boolean;
}

export class RootHeader extends React.Component<any, IRootHeaderState> {
  private readonly _urlState: UrlState;
  private readonly _authenticationService: AuthenticationService;

  public state: IRootHeaderState;

  public constructor(props) {
    super(props);
    this._urlState = UrlState.getInstance();
    this._authenticationService = AuthenticationService.getInstance();
    this.state = {accountVisible: false};
  }

  private _signOut = async () => {
    await this._authenticationService.signOut();
    window.location.hash = '#';
  }

  private _handleClickAction = (key: string): void => {
    if (key === 'logout') this._signOut();
  }

  private _renderAccountMenu = (): any => (
    <ActionList onClick={(key) => this._handleClickAction(key)} className="AccountList">
      {/*<ActionList.Action key="profile" className="AccountList__Item">{lang('profile')}</ActionList.Action>*/}
      <ActionList.Action key="logout" className="AccountList__Item">{lang('log_out')}</ActionList.Action>
    </ActionList>)

  public render() {
    const {segment} = this._urlState?.getModel();

    const username = skin.datasetLogoTitle || this._authenticationService.getModel()?.username;
    return (
      <Header style={{minHeight: '56px'}}>
        <LoadFromResources path="RootHeader.js">
          <Header.Title>
            {!segment && <a href="/#/"><Logo/></a>}
          </Header.Title>
          <BreadcrumbControl/>
          <div className="RootHeader__Toolbar">
            <RootSearch/>
            <MainToolbar__EditModeButton/>
            <HrefButton href="/docs/" title="Документация" target="_blank" icon="info2" active={false} visible={true} viewClassId="MainToolbar/HrefButton"/>
            <MainToolbar__ThemeSwitchButton/>
          </div>
          <div className="RootHeader__Toolkit">
            <AccountTool visible={this.state.accountVisible}
                         onClickOutside={() => this.setState({accountVisible: false})}
                         onClick={() => this.setState({accountVisible: !this.state.accountVisible})}
                         menu={this._renderAccountMenu()}
                         title={username}/>
          </div>
        </LoadFromResources>
      </Header>);
  }
}