/// <reference path="../../defs/bi.d.ts" />

/**
 *   Dataset shell
 */

import React, {useRef, useState} from 'react';
import './DsShell.scss';
import cn from 'classnames';
import { IDsShellVM } from '../../view-controllers/DsShellVC';
import {AppConfig, extractErrorMessage, repo, srv, urlState, UrlState} from '@luxms/bi-core';
import { lang, MessageHub, ruKbdToEng } from '../../utils/utils';
import { toggleActiveClass } from '../view-utils';
import { DsShellHeader } from './DsShellHeader';
import { DsShellLeftPane } from './DsShellLeftPane';
import { DsShellTitle } from './DsShellTitle';
import { DsShellError } from './DsShellError';
import debounce from 'lodash/debounce';
import Strap from '@luxms/bi-face/Strap';
import { useServiceItself } from '../useService';
import EditModeVC from '../../view-controllers/EditModeVC';
import { Button } from '@luxms/bi-face';
import { DsStateService } from '../../services/ds/DsStateService';
import {IDashboard} from '../../services/ds/types';
import {TransactionEntitiesService} from '../../services/TransactionEntitiesService';
import IRawDashlet = repo.ds.IRawDashlet;
import LoadFromResources from '../components/LoadFromResources';
import SetTheme from "../components/SetTheme";
import $ from 'jquery';


const PanelMetrics = React.lazy(() => import('../panels/PanelMetrics/PanelMetrics'));
const PanelLocations = React.lazy(() => import('../panels/PanelLocations/PanelLocations'));
const PanelPeriods = React.lazy(() => import ('../panels/PanelPeriods/PanelPeriods'));

interface IDraggableAProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  direction: 'vertical' | 'horizontal';
}

const DragableA = React.memo((props: IDraggableAProps) => {

  const myRef = useRef<HTMLAnchorElement>();
  const [draggingToggle, setDraggingToggle] = useState(false);
  const [previousTop, setPreviousTop] = useState(0);
  const [previousLeft, setPreviousLeft] = useState(0);
  let [moved, setMoved] = useState(false);

  const positionDelta = (event) => {
    const top = event.pageY;
    const left = event.pageX;
    const delta = {
      top: top - previousTop,
      left: left - previousLeft,
    };
    setPreviousTop(top);
    setPreviousLeft(left);
    return delta;
  };

  const onPointerDown = (event) => {
    setMoved(false);
    event.target.setPointerCapture(event.pointerId);
    positionDelta(event);
    setDraggingToggle(true);
  };

  const onPointerMove = (event) => {

    if (!draggingToggle) {
      return;
    }
    const {top} = positionDelta(event);
    const {left} = positionDelta(event);
    const parent = myRef.current!.parentElement;
    const clamp = (v, min, max) => Math.max(min, Math.min(max, v));

    if (props.direction === 'vertical') {
      if (top > 0.6 || top < -0.6) setMoved(true);
      const elHeight = parseFloat(getComputedStyle(myRef.current).height)  / 2;
      const elHPercent = elHeight / parent.offsetHeight * 100;
      const currentPxTop = parseFloat(getComputedStyle(myRef.current).top);
      myRef.current!.style.top  = clamp((currentPxTop + top) / parent.offsetHeight * 100, elHPercent, 100 - elHPercent * 1.5) + '%';
    }

    if (props.direction === 'horizontal') {
      if (left > 0.6 || left < -0.6) setMoved(true);
      const elWidth = parseFloat(getComputedStyle(myRef.current).width)  / 2;
      const elWPercent = elWidth / parent.offsetWidth * 100;
      const currentPxLeft = parseFloat(getComputedStyle(myRef.current).left);
      myRef.current!.style.left = clamp((currentPxLeft + left) / parent.offsetWidth * 100, elWPercent, 100 - elWPercent) + '%';
    }

    setDraggingToggle(true);
  };

  const onPointerUp = (event) => {
    setDraggingToggle(false);
  };

  return <a {...props}
            ref={myRef}
            onClick={moved ? null : props.onClick}
            onPointerDown={onPointerDown}
            onPointerMove={onPointerMove}
            onPointerUp={onPointerUp}>
    {props.children}
  </a>;
});


const DsShell__Header = ({schema_name, dsTitle, dsDescription, dsUrl}: {schema_name: string, dsTitle: string, dsDescription: string, dsUrl: string}) => {
  const editModeVC = useServiceItself<EditModeVC>(EditModeVC);
  const editMode = editModeVC.getModel().editMode;

  // TODO: сделать анимацию переключения режимов - объединить в одном контейнере
  if (editMode) {
    return (
      <header className="DsShell__Header editMode">
        <Button variant="primary" onClick={() => editModeVC.reset()}>{lang('cancel')}</Button>
        <Button variant="success" onClick={() => editModeVC.save()}>{lang('save')}</Button>
      </header>);
  }

  if (!schema_name) {
    return <header className="DsShell__Header"/>;
  }

  return (
    <LoadFromResources path="DsShellHeader.js"
                       schema_name={schema_name}
                       dsTitle={dsTitle}
                       dsDescription={dsDescription}
                       dsUrl={dsUrl}>
      <header className="DsShell__Header">
        <DsShellHeader schema_name={schema_name}
                       dsTitle={dsTitle}
                       dsDescription={dsDescription}
                       dsUrl={dsUrl}/>

        <DsShellTitle schema_name={schema_name}
                      dsTitle={dsTitle}
                      dsDescription={dsDescription}
                      dsUrl={dsUrl}/>
      </header>
    </LoadFromResources>);
};


let isEastPanelOpenBySwitchDataset = false;

interface IDsShellState {
  error: string;
  Module: any;
  useSinglePeriod: boolean;
  westPanelEnabled: boolean;
  eastPanelEnabled: boolean;
  northPanelEnabled: boolean;
  westPanelOpened: boolean;
  eastPanelOpened: boolean;
  northPanelOpened: boolean;
  eastPanelLoading: boolean;
  eastPanelLoaded: boolean;
  draggingToggle: boolean;
  shellWidth: number;
  shellHeight: number;
  isEastKoobControl: boolean;
  dboard?: IDashboard;
  displayMode: string;
}

export class DsShell extends React.Component<IDsShellVM, IDsShellState> {
  public state: IDsShellState = {
    error: null,
    Module: null,
    westPanelEnabled: false,
    eastPanelEnabled: false,
    northPanelEnabled: false,
    useSinglePeriod: false,
    westPanelOpened: false,
    eastPanelOpened: isEastPanelOpenBySwitchDataset,
    northPanelOpened: false,
    eastPanelLoading: true,
    eastPanelLoaded: false,
    draggingToggle: false,
    shellWidth: null,
    shellHeight: null,
    isEastKoobControl: false,
    dboard: null,
    displayMode: '',
  };

  private _dashletsService: TransactionEntitiesService<IRawDashlet> | null = null;
  public documentTitle = '';

  private _loadingModule: string = '';                                                              // currently loading module name
  private _panelIsKoobControl = false;

  private _scroll: any;
  private _messages = {
    closePanel: (event, sender, params) => {
      const pane = params.pane;
      if (pane === 'east') this.hideEastPanel();
      if (pane === 'west') this.hideWestPanel();
      if (pane === 'north') this.hideNorthPanel();
    },
  };

  private _dsStateService: DsStateService;

  public constructor(props: IDsShellVM) {
    super(props);

    if (props.state && props.dataset) {
      const ch = props.dataset.getConfigHelper();
      const disableMLP = this._checkDisableMLP();

      this.state.westPanelEnabled = !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide');
      this.state.eastPanelEnabled = ch.getBoolValue('panel.locations.hide');
      this.state.northPanelEnabled = !AppConfig.hasFeature('DisableMLP') && !ch.getBoolValue('panel.periods.hide');
      if (ch.getStringValue('panel.locations.type') && ch.getStringValue('panel.locations.type').indexOf('dashlet') != -1) this._panelIsKoobControl = true;
    }

    if (props.route) {
      this._routeChanged(props.route);
    }

    // Подписка для обновления панелей из-за встраемого виджета включаемого в опциях
    this._dashletsService = srv.ds.DashletsService.createInstance(this.props.schema_name) as any;
    this._dashletsService.subscribeUpdates(this._onDashboardsUpdate);
  }

  private _onDashboardsUpdate = (model: any) => {
    if (model.loading) return;

    const ch = this.props.dataset.getConfigHelper();
    const disableMLP = this._checkDisableMLP();
    this.setState({
      eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
    });

  }

  public componentDidUpdate(prevProps: Readonly<IDsShellVM>, prevState: Readonly<IDsShellState>, snapshot?: any) {
    if (!prevProps || this.props.route !== prevProps.route) {
      this._routeChanged(this.props.route);
    }

    if (prevState.dboard !== this.state.dboard) {
      const ch = this.props.dataset.getConfigHelper();
      const disableMLP = this._checkDisableMLP();
      this.setState({
        eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
      });
    }
  }

  private _checkDisableMLP = (): boolean => {
    const dashletInPanel = this._checkDashletInPanel();
    return (AppConfig.hasFeature('DisableMLP')) ? (!dashletInPanel) ? false : true : true;
  }

  private _checkDashletInPanel = (): boolean => {
    let dashletInPanel: boolean = false;

    const panelLocationsType = this.props.state?.getDataset()?.getConfigHelper()?.getStringValue('panel.locations.type');
    const stateDashletsIds = this.props.state?.getModel().dboard?.getDashes()?.map(d => d.id) || [];
    const dashlets = this._dashletsService?.getModel() || [];

    const dashes = stateDashletsIds.filter((id) => {
      const dash = dashlets.find((d) => String(d.id) == String(id));
      if (dash && dash?.config?.options?.includes('EastPanel')) return dash;
    });

    const checkPanel = panelLocationsType?.match(/^dashlet\((\d+)\)$/);
    dashletInPanel = (!!checkPanel || dashes.length > 0);

    return dashletInPanel;
  }

  private _applyModuleOpts = (customModuleOpts: any): void => {
    let moduleOpts: any = {
      useSinglePeriod: false,
      metricsPanel: true,
      locationsPanel: true,
      periodsPanel: true,
      ...customModuleOpts,
    };

    const ch = this.props.dataset.getConfigHelper();
    const disableMLP = this._checkDisableMLP();

    const westPanelEnabled = !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide');
    const eastPanelEnabled = disableMLP;
    const northPanelEnabled = !AppConfig.hasFeature('DisableMLP') && !ch.getBoolValue('panel.periods.hide');

    this.setState({
      useSinglePeriod: moduleOpts.useSinglePeriod,
      westPanelEnabled: westPanelEnabled && moduleOpts.metricsPanel,
      eastPanelEnabled: eastPanelEnabled && moduleOpts.locationsPanel,
      northPanelEnabled: northPanelEnabled && moduleOpts.periodsPanel,
      isEastKoobControl: this._panelIsKoobControl,
    });
    if (moduleOpts.fullscreen) this.enterFullscreen(); else this.exitFillscreen();
  }

  private _routeChanged = (route: string) => {
    route = (route || '').replace(/^#/, '');

    switch (route) {    // some old-style fixes
      case 'dashboard':
        route = 'dashboards';
        break;
      case 'plots':
        route = 'trends';
        break;
    }

    if (route === '' && this.props.dataset) {
      const ds = this.props.dataset;
      const ch = ds.getConfigHelper();
      route = ch.getStringValue('startup.page', 'dashboards');
    }

    if (!route) {
      this.setState({Module: null});
      return;
    }

    const modulePath: string = `../ds/${route}`;
    this._loadingModule = modulePath;

    import(`../ds/${route}`).then((Module) => {
      if (modulePath !== this._loadingModule) {              // asynchronous requests guard
        return;
      }
      try {
        if ('default' in Module) {
          Module = Module.default;
        }
        this.setState({error: null, Module});

        // TODO:
        // this._applyModuleOpts(module);

        this.documentTitle = document.title = (lang(route) ? lang(route) + ' | ' : '') + AppConfig.getProjectTitle();

      } catch (err) {
        console.error(`Error creating root module: '${route}' for path: '${modulePath}'`, err);
        console.log(err.stack);
      }
    }, (err) => {
      console.error(`Error getting module: '${modulePath}'`);
      if (err.code === 'MODULE_NOT_FOUND') {
        const variants = ['dashboards', 'edt-dashboards', 'trends', 'map', 'edt-entities', 'resources', 'dbg-vizel'].map(r => ({r, d: strDist(r, route)})).filter(({d}) => d > 0.8).sort((a, b) => b.d - a.d);
        if (variants[0]) {
          UrlState.getInstance().navigate({route: variants[0].r}, {replace: true, trigger: true});
          return;
        }
      }
      this.setState({error: extractErrorMessage(err), Module: null});
    });
  }

  public resize = debounce(() => {
    let container: HTMLElement = document.querySelector('.DsShell__Body');
    import('iscroll').then((IScroll: any) => {
      let container: HTMLElement = document.getElementById('DsShellPanel');
      if (!container) return;
      if (container?.children[0]) (container.children[0] as any).style.width = 3000 + 'px';
      IScroll = IScroll.default || IScroll;
      window.setTimeout(() => {
        this._scroll = new IScroll(container, {
          // probeType: 1,
          // mouseWheel: true,
          // scrollbars: false,
          // interactiveScrollbars: true,
          // scrollX: true,
          // scrollY: false,
          // click: true,
          // tap: true,
          // useTransform: true,
          // useTransition: false,
          // preventDefault: false,
          mouseWheel: true,
          scrollbars: false,
          interactiveScrollbars: true,
          scrollX: true,
          scrollY: false,
          // click: true,
          tap: true,
          //  snap: true,
          momentum: false,
          // useTransform: true,
          // useTransition: false,
          // probeType: 3,
        });
        this._scroll.refresh();
      }, 200);
    });

    if (container) {
      this.setState({shellWidth: container.clientWidth, shellHeight: container.clientHeight});
    }

    // TODO
    // try {
    //   if (this.root() && typeof this.root().resize === 'function') this.root().resize();
    // } catch (err) {
    //   console.error('Error calling resize method on root', err);
    //   console.log(err.stack);
    // }

    // TODO:
    // [this.metricsPanel(), this.locationsPanel(), this.periodsPanel()].forEach((panel) => {
    //   try {
    //     if (panel && ('resize' in panel)) {
    //       panel.resize();
    //     }
    //   } catch (err) {
    //     console.error('Error calling resize method on panel', err);
    //     console.log(err.stack);
    //   }
    // });
  }, 150);

  public onUrlChanged = (url) => {
    let displayMode = url.displayMode || 'default';
    if (String(displayMode).indexOf('dashlet') === 0) {
      displayMode = 'dashlet';
    }
    this.setState({displayMode});
  }

  private _onRouteOrDboardChange = (model: any) => {
    if (model) {
      this.setState(() => ({
        dboard: model.dboard,
      }));
    }
  }

  public componentDidMount() {

    this._dsStateService = DsStateService.createInstance(this.props.schemaName);
    this._dsStateService.subscribe('route dboard', this._onRouteOrDboardChange);

    UrlState.subscribeUpdatesAndNotify(this.onUrlChanged);
    if (this.props.state && this.props.dataset) {
      const ch = this.props.dataset.getConfigHelper();

      const disableMLP = this._checkDisableMLP();

      this.setState({
        westPanelEnabled: !ch.getBoolValue('panel.parameters.hide') && !ch.getBoolValue('panel.metrics.hide'),
        eastPanelEnabled: disableMLP && !ch.getBoolValue('panel.locations.hide'),
        northPanelEnabled: !ch.getBoolValue('panel.periods.hide'),
      });
      if (ch.getStringValue('panel.locations.type') && ch.getStringValue('panel.locations.type').indexOf('dashlet') != -1) this._panelIsKoobControl = true;
    }

    // TODO: remove
    for (let message in this._messages) {
      MessageHub.receive(message, this._messages[message]);
    }
  }

  public componentWillUnmount() {
    this._dsStateService.unsubscribe(this._onRouteOrDboardChange);
    this._dsStateService.release();
    this._dsStateService = null;

    this._dashletsService.unsubscribe(this._onDashboardsUpdate);
    this._dashletsService.release();
    this._dashletsService = null;

    for (let message in this._messages) {
      MessageHub.off(message, this._messages[message]);
    }
  }


  public toggleWestPanel = () => this.state.westPanelOpened ? this.hideWestPanel() : this.showWestPanel();
  public toggleEastPanel = () => this.state.eastPanelOpened ? this.hideEastPanel() : this.showEastPanel();
  public toggleNorthPanel = () => this.state.northPanelOpened ? this.hideNorthPanel() : this.showNorthPanel();

  public hideWestPanel  = () => { this.setState({westPanelOpened: false}); this.resize(); };
  public hideEastPanel  = () => { this.setState({eastPanelOpened: false}); isEastPanelOpenBySwitchDataset = false; this.resize(); };
  public hideNorthPanel = () => { this.setState({northPanelOpened: false});  };

  public showWestPanel = () => { this.setState({westPanelOpened: true}); this.resize(); };
  public showEastPanel = () => {
    this.setState({eastPanelOpened: true, eastPanelLoaded: true});
    isEastPanelOpenBySwitchDataset = true;
    this.resize();
  }
  private showNorthPanel = () => {this.setState({northPanelOpened: true}); };

  public enterFullscreen = () => document.body.classList.add('ds-fullscreen');
  public exitFillscreen = () => document.body.classList.remove('ds-fullscreen');
  public removePreloaderForEastPanel = (status: boolean = true) => {
    this.setState({eastPanelLoading: !status});
  }

  private _setupModuleRef = (moduleRef: any) => {
    if (moduleRef?.getModuleOptions) {
      this._applyModuleOpts(moduleRef.getModuleOptions());
    }
  }

  public render() {
    const { loading, schemaName, state, datasetTitle, datasetDescriptionHTML, datasetUrl } = this.props;
    let { route } = this.props;
    const { error, Module, westPanelEnabled, eastPanelEnabled, northPanelEnabled, westPanelOpened, eastPanelOpened,
            northPanelOpened, useSinglePeriod, eastPanelLoading, eastPanelLoaded, draggingToggle, shellWidth, shellHeight, isEastKoobControl, displayMode } = this.state;
    const eastPanelPinned = $('.DsShell').hasClass('EastPanelPinned');
    const urlRoute = urlState.getModel().route;
    if (route !== urlRoute) route = urlRoute;
    if (this.props.error) {
      return (
        <article className="DsShell error">
          <DsShellError error={this.props.error}/>
        </article>);
    }

    return (
      <article className={
        cn('DsShell',
          {
            error: !!error,
            westPanelOpened,
            eastPanelOpened,
            northPanelOpened,
            WestPanelOpened: westPanelOpened,
            EastPanelOpened: eastPanelOpened,
            NorthPanelOpened: northPanelOpened,
            WestPanelDisabled: !westPanelEnabled,
            EastPanelDisabled: urlState.getModel().path[2] === 'edt-entities',
            NorthPanelDisabled: !northPanelEnabled,
            EastPanelKoobControl: isEastKoobControl,
            [`mode-${displayMode}`]: true
          })}
               data-route={route?.replace(/^#/, '')}
               data-display-mode={displayMode}
               data-schema-name={schemaName}>

        <Strap>
          {!!schemaName && <LoadFromResources path="DsShellLeftPane.js" schema_name={schemaName}><DsShellLeftPane schema_name={schemaName}/></LoadFromResources>}
          <Strap.Body>

            {eastPanelOpened && !eastPanelPinned &&
              <div className="Shadow" style={{width: '100%', height: '100%', zIndex: 2}} onClick={this.hideEastPanel}></div>
            }

            {/*<DsShell__Header schema_name={schemaName}
                             dsTitle={datasetTitle}
                             dsDescription={datasetDescriptionHTML}
                             dsUrl={datasetUrl}/>*/}

            {!!loading &&
            <img className="DsShell__MainLoadingImage main-loading-image" src="../../../assets/logo/logo-animated.svg"/>}

            {/*<a className="toggle ToggleWestPanel"  href={void(0)} onClick={this.toggleWestPanel}>metrics</a>*/}
            {/*<a className="toggle ToggleEastPanel"  href={void(0)} onClick={this.toggleEastPanel}>locations</a>*/}
            {/*<a className="toggle ToggleNorthPanel" href={void(0)} onClick={this.toggleNorthPanel}>periods</a>*/}
            <SetTheme theme={'dark'}>
              <DragableA className="toggle ToggleEastPanel"
                         href={void(0)}
                         direction="vertical"
                         onClick={this.toggleEastPanel} >
                metrics
              </DragableA>
            <aside className={cn('EastPanel', {loading: this._panelIsKoobControl && eastPanelLoading})}>
              {((this._panelIsKoobControl && eastPanelLoaded) || (eastPanelOpened)) &&
              <React.Suspense fallback={null}>
                <PanelLocations schema_name={schemaName} dsStateService={state} loadingIndicatorFunc={this.removePreloaderForEastPanel}/>
              </React.Suspense>}
            </aside>
            </SetTheme>
            <aside className="WestPanel">
              <DragableA className="toggle ToggleWestPanel"
                         href={void(0)}
                         direction="vertical"
                         onClick={this.toggleWestPanel} >
                locations
              </DragableA>
              {westPanelOpened &&
              <React.Suspense fallback={null}>
                <PanelMetrics schema_name={schemaName} dsStateService={state}/>
              </React.Suspense>}
            </aside>

            <aside className="NorthPanel">
              <DragableA className="toggle ToggleNorthPanel"
                         href={void(0)}
                         direction="horizontal"
                         onClick={this.toggleNorthPanel} >
                periods
              </DragableA>
              {northPanelOpened &&
              <React.Suspense fallback={null}>
                <PanelPeriods schema_name={schemaName} dsStateService={state} useSinglePeriod={useSinglePeriod}/>
              </React.Suspense>}
            </aside>

            {!!error && <div className="DsShell__Error">{error}</div>}

            {/*<!-- root -->*/}
            <section className="DsShell__Body dataset-main"
                     style={{zIndex: 1}}>
              {!!Module &&
              <Module ref={this._setupModuleRef} dsStateService={state} schema_name={schemaName} applyModuleOpts={this._applyModuleOpts} width={shellWidth} height={shellHeight}/>}
            </section>
          </Strap.Body>
        </Strap>
      </article>);
  }
}


// Похожесть слов для поиска ошибок в URL route
const LETTERS_SIMILARITY = ['qwsz', 'vghn', 'xdfv', 'serfcx', 'wsdr', 'rdcvg', 'fthbv', 'ygbnj', 'ujko', 'hukmn', 'ijm,l', 'kop;,.', 'njk,', 'bhjm', 'iklp', 'ol;[', 'wa', 'edft', 'awdxz', 'rfgy', 'yjih', 'cfgb', 'qase', 'zsdc', 'tghu', 'asx'];
const lsHelper = (a, b) => a && b && 'a' <= a && a <= 'z' && LETTERS_SIMILARITY[a.charCodeAt(0) - 'a'.charCodeAt(0)].includes(b) ? 1 : 0;
const letterSimilarity = (l1, l2) => Math.max(l1 === l2 ? 1 : 0, 0.5 * lsHelper(l1, l2), 0.5 * lsHelper(l2, l1), 0);
const trigramSimilarity = (t1, t2) => (letterSimilarity(t1[0], t2[0]) + letterSimilarity(t1[1], t2[1]) + letterSimilarity(t1[2], t2[2])) / 3;
const sum = (xs) => xs.reduce((a, b) => a + b, 0);
const mostSimilarWithArray = (t, ts) => ts.reduce((a, tt) => Math.max(trigramSimilarity(t, tt), a), 0);
const trigramsSimilarity = (ts1, ts2) => (sum(ts1.map(t => mostSimilarWithArray(t, ts2))) + sum(ts2.map(t => mostSimilarWithArray(t, ts1)))) / (ts1.length + ts2.length);
const makeTrigrams = (s) => s.split('').map((_, i) => s.slice(i, i + 3));
const strDist = (s1, s2) => trigramsSimilarity(makeTrigrams(ruKbdToEng(s1.toLowerCase())), makeTrigrams(ruKbdToEng(s2.toLowerCase())));


export default DsShell;

