import React from 'react';
import useService from './useService';
import ResourceLocatorService from '../services/ResourceLocatorService';
import {Helmet} from 'react-helmet';
import { AppConfig } from '@luxms/bi-core';


// TODO: использовать React Helmet

const CustomStyleLoader = () => {
  const dsResStyles = useService<ResourceLocatorService>(ResourceLocatorService, 'ds_res', 'styles.css');
  const dsStyles = useService<ResourceLocatorService>(ResourceLocatorService, null, 'styles.css');

  return (
    <Helmet>
      {!!dsResStyles.resource &&
        <link key="ds_res-custom-style" data-purpose="ds_res-custom-style" rel="stylesheet" href={AppConfig.fixRequestUrl(`/srv/resources/${dsResStyles.schema_name}/styles.css?ts=${dsResStyles.resource.updated}`)}/>}

      {!!dsStyles.resource &&
        <link key="ds-custom-style" data-purpose="ds-custom-style" rel="stylesheet" href={AppConfig.fixRequestUrl(`/srv/resources/${dsStyles.schema_name}/styles.css?ts=${dsStyles.resource.updated}`)}/>}
    </Helmet>);
};

export default CustomStyleLoader;
