/// <reference path="../../defs/moment.d.ts" />
/// <reference path="../../defs/bi.d.ts" />

'use strict';

import {
  VizelConfig,
  ConfigHelper,
  MetricsHelper,
  LocationsHelper,
  PeriodsHelper,
  DashletsHelper,
  UnitsHelper,
  LocationAreasHelper,
  PresetsHelper,
  LocationCardFieldsHelper,
  LocationCardsHelper,
} from './ds-helpers';
import { lang, markContinuousPeriodType } from '../../utils/utils';
import { BiQuery } from '../biquery';
import { IUrl, Observable, RtService } from '@luxms/bi-core';
import { $eid, $esid } from '../../libs/imdas/list';
import { IConfigHelper, IDashboard, IDatasetModel, IVizelConfig } from './types';
import { IBiQuery } from '../../data-manip/data-manip';
import {
  ILocation,
  ILocationArea,
  ILocationCard,
  IMetric, IMLPVPtr,
  IPeriod, IPeriodInfo,
  IPreset,
  IUnit,
  responses,
  tables
} from '../../defs/bi';


interface IDatasetStartup {
  startPeriod: IPeriod;
  endPeriod: IPeriod;
  locations: ILocation[];
  metrics: IMetric[];
}


function _buildStartup(configsHelper: IConfigHelper, allMetrics: IMetric[], presets: IPreset[], allLocations: ILocation[], periods: IPeriod[]): IDatasetStartup {
  const startupMIds: string[] = configsHelper.getStringArray('startup.metrics');
  const startupLIds: string[] = configsHelper.getStringArray('startup.locations', []);
  const startupPresetId: string = String(configsHelper.getIntValue('startup.preset.id'));
  const startupPreset: IPreset = $eid(presets, startupPresetId);
  const startPeriodId: string = configsHelper.getStringValue('startup.period.start');
  const endPeriodId: string = configsHelper.getStringValue('startup.period.id');

  const startPeriod: IPeriod = $eid(periods, startPeriodId);
  const endPeriod: IPeriod = $eid(periods, endPeriodId);
  const locations: ILocation[] = $esid(allLocations, startupLIds);
  let metrics: IMetric[] = [];
  if (startupPreset) {
    metrics = startupPreset.metrics;
  } else if (startupMIds) {
    metrics = $esid(allMetrics, startupMIds);
  }
  return {
    startPeriod,
    endPeriod,
    locations,
    metrics,
  };
}


// debug helper
function debugAddPeriods(datasetDescription, storage, dataset) {
  let i = 1;
  let periods = storage.periods;
  storage.periods = periods.slice(0, i);

  (window as any).nextPeriod = (num = 1) => {
    i += num;
    storage = {
      ...storage,
      periods: periods.slice(0, i),
    };
    dataset.update(datasetDescription, storage);
  };
}


export class DatasetModel extends Observable implements IDatasetModel {
  public readonly id: number;
  public readonly guid: string = null;
  public readonly schemaName: string = null;
  public readonly schema_name: string = '';
  public readonly title: string = null;
  public readonly description: string = null;

  public M: (id: string) => IMetric = null;
  public L: (id: string | number) => ILocation = null;
  public P: (id: string) => IPeriod = null;

  public units: IUnit[];
  public metrics: IMetric[];
  public rootMetrics: IMetric[];
  public presets: IPreset[];
  public locationCards: ILocationCard[];
  public locationAreas: ILocationArea[];
  public locations: ILocation[];
  public rootLocations: ILocation[];
  public periods: IPeriod[];
  public dashboards: IDashboard[];

  public defaultMetrics: IMetric[];
  public defaultLocations: ILocation[];
  public defaultPeriods: IPeriod[];

  // helpers
  private readonly _configsHelper: ConfigHelper;
  private readonly _unitsHelper: UnitsHelper;
  public readonly metricsHelper: MetricsHelper;
  private readonly _presetsHelper: PresetsHelper;
  private readonly _locationCardFieldsHelper: LocationCardFieldsHelper;
  private readonly _locationCardsHelper: LocationCardsHelper;
  private readonly _locationAreasHelper: LocationAreasHelper;
  public readonly locationsHelper: LocationsHelper;
  public readonly periodsHelper: PeriodsHelper;
  public readonly dashletsHelper: DashletsHelper;

  private _startup: IDatasetStartup = null;
  private _serial: moment.Moment = null;

  private _biQuery: IBiQuery = null;

  public constructor(datasetDescription: responses.IDatasetDescription, storage: responses.ITables) {
    super();
    this.id = datasetDescription ? Number(datasetDescription.id) : null;
    this.guid = datasetDescription && ('guid' in datasetDescription) ? datasetDescription?.guid : String(this.id);
    this.schema_name = this.schemaName = datasetDescription?.schema_name;
    this.title = datasetDescription?.title || '';
    this.description = datasetDescription?.description || '';

    // hack: parse datafile to extract id if no id provided (= guid)
    if (String(this.id) === this.guid) {
      const datafile: string = datasetDescription?.datafile || datasetDescription?.sqlite_file;
      this.id = String(datafile).match(/(\d+)/) && +RegExp.$1 || -1;
    }

    // debugAddPeriods(datasetDescription, storage, this);

    const rawConfigs: tables.IConfigItem[] = storage?.config;
    const rawDsConfig: any = datasetDescription?.config;
    const rawUnits: tables.IUnitsItem[] = storage.units || storage.dimensions;
    const rawMetrics: tables.IMetricsItem[] = storage.metrics || storage.params;
    const rawPresets: tables.IPresetsItem[] = storage.metric_sets || storage.presets;
    const rawLocationCardFields: tables.ILocationCardField[] = storage.location_card_fields;
    const rawLocationCards: tables.ILocationCard[] = storage.location_cards;
    const rawLocations: tables.ILocationsItem[] = storage.locations;
    const rawLocationAreas: tables.ILocationArea[] = storage.location_areas || storage.spatial;
    const rawPeriods: tables.IPeriodsItem[] = storage.periods;
    const rawDashboards: tables.IDashboardsItem[] = storage.dashboards;
    const rawDashboardTopics: tables.IDashboardTopic[] = storage.dashboard_topics;
    const rawDashlets: tables.IDashletsItem[] = storage.dashlets || storage.dashes || storage.dashboard_views;

    this._configsHelper = new ConfigHelper(rawConfigs, rawDsConfig);
    this._unitsHelper = new UnitsHelper(rawUnits);
    this.metricsHelper = new MetricsHelper(rawMetrics, this._unitsHelper.entities, this._configsHelper);
    this._presetsHelper = new PresetsHelper(rawPresets, this.metricsHelper.metrics);
    this._locationCardFieldsHelper = new LocationCardFieldsHelper(rawLocationCardFields, this.metricsHelper.metrics);
    this._locationCardsHelper = new LocationCardsHelper(rawLocationCards, this._locationCardFieldsHelper.entities);
    this._locationAreasHelper = new LocationAreasHelper(rawLocationAreas);
    this.locationsHelper = new LocationsHelper(rawLocations, this._locationCardsHelper.entities, this._locationAreasHelper.entities);
    this.periodsHelper = new PeriodsHelper(rawPeriods, this._configsHelper.getIntValue('startup.periodType', null));
    this.dashletsHelper = new DashletsHelper(this, rawDashboards, rawDashboardTopics, rawDashlets);

    this.units = this._unitsHelper.entities;
    this.metrics = this.metricsHelper.metrics;
    this.rootMetrics = this.metricsHelper.rootMetrics;
    this.presets = this._presetsHelper.entities;
    this.locationCards = this._locationCardsHelper.entities;
    this.locationAreas = this._locationAreasHelper.entities;
    this.locations = this.locationsHelper.locations;
    this.rootLocations = this.locationsHelper.roots;
    this.periods = this.periodsHelper.periods;
    this.dashboards = this.dashletsHelper.dashboards;

    this.periodsHelper.subscribe('update', () => {
      this.periods = this.periodsHelper.periods;
      this._notify('periodsUpdated', this.periodsHelper);
      this._notify('update', this);
    });

    this.M = (id: string): IMetric => $eid(this.metrics, id);
    this.L = (id: string | number): ILocation => $eid(this.locations, id);
    this.P = (id: string): IPeriod => $eid(this.periods, id);

    this._biQuery = new BiQuery(storage.data, this);

    this._serial = moment(datasetDescription?.serial);
    this._startup = _buildStartup(this._configsHelper, this.metrics, this.presets, this.locations, this.periods);

    this.defaultMetrics = this._startup.metrics;
    this.defaultLocations = this._startup.locations;
    this.defaultPeriods = this.getPeriodInfoByRange(null, null).periods;

    // DEBUG
    if (typeof window !== 'undefined')  (window as any).storage = storage;
    (window as any).testUpdateCurrentDataset = () => this.update(datasetDescription, {...storage});

    // TODO: move to DatasetService
    // TODO: add to subscriptions
    RtService.getInstance().subscribeDataset(this.schema_name, this._onRtMessage);
  }

  public update(datasetDescription: responses.IDatasetDescription, storage: responses.ITables): void {
    // if planning to test Network based BiQuery, uncomment the following line:
    // delete storage.data;

    const rawConfigs: tables.IConfigItem[] = storage.config;
    const rawDsConfig: any = datasetDescription.config;
    const rawUnits: tables.IUnitsItem[] = storage.units || storage.dimensions;
    const rawMetrics: tables.IMetricsItem[] = storage.metrics || storage.params;
    const rawPresets: tables.IPresetsItem[] = storage.metric_sets || storage.presets;
    const rawLocationCardFields: tables.ILocationCardField[] = storage.location_card_fields;
    const rawLocationCards: tables.ILocationCard[] = storage.location_cards;
    const rawLocations: tables.ILocationsItem[] = storage.locations;
    const rawLocationAreas: tables.ILocationArea[] = storage.location_areas || storage.spatial;
    const rawPeriods: tables.IPeriodsItem[] = storage.periods;
    const rawDashboards: tables.IDashboardsItem[] = storage.dashboards;
    const rawDashboardTopics: tables.IDashboardTopic[] = storage.dashboard_topics;
    const rawDashlets: tables.IDashletsItem[] = storage.dashlets || storage.dashboard_views;

    this._configsHelper.update(rawConfigs, rawDsConfig);
    this._unitsHelper.update(rawUnits);
    this.metricsHelper.update(rawMetrics, this._unitsHelper.entities);
    this._presetsHelper.update(rawPresets, this.metricsHelper.metrics);
    this._locationCardFieldsHelper.update(rawLocationCardFields, this.metricsHelper.metrics);
    this._locationCardsHelper.update(rawLocationCards, this._locationCardFieldsHelper.entities);
    this._locationAreasHelper.update(rawLocationAreas);
    this.locationsHelper.update(rawLocations, this._locationCardsHelper.entities, this._locationAreasHelper.entities);
    this.periodsHelper.update(rawPeriods, this._configsHelper.getIntValue('startup.periodType', null));
    this.dashletsHelper.update(rawDashboards, rawDashboardTopics, rawDashlets);

    // todo: move code to ds-service
    this.units = this._unitsHelper.entities;
    this.metrics = this.metricsHelper.metrics;
    this.rootMetrics = this.metricsHelper.rootMetrics;
    this.presets = this._presetsHelper.entities;
    this.locationCards = this._locationCardsHelper.entities;
    this.locationAreas = this._locationAreasHelper.entities;
    this.locations = this.locationsHelper.locations;
    this.rootLocations = this.locationsHelper.roots;
    this.periods = this.periodsHelper.periods;
    this.dashboards = this.dashletsHelper.dashboards;

    this._serial = moment(datasetDescription.serial);
    this._startup = _buildStartup(this._configsHelper, this.metrics, this.presets, this.locations, this.periods);

    // DEBUG
    if (typeof window !== 'undefined')  (window as any).storage = storage;
  }

  private _onRtMessage = (messageType: string, payload: any): void => {
    if (messageType === 'ADD_DATAS' || messageType === 'CREATE_DATAS') {
      (payload as IMLPVPtr[]).forEach((mlpvPtr: IMLPVPtr) => {
        const {metric_id, loc_id, period_id, val} = mlpvPtr;
        this._biQuery.notifyDataChange(metric_id, loc_id, period_id, val);
      });
    } else if (messageType === 'ADD_DATA' || messageType === 'CREATE_DATA') {
      const {metric_id, loc_id, period_id, val} = payload as IMLPVPtr;
      this._biQuery.notifyDataChange(metric_id, loc_id, period_id, val);
    } else if (messageType === 'ADD_PERIODS' || messageType === 'CREATE_PERIODS') {
      this.periodsHelper.addPeriods(payload as tables.IPeriodsItem[]);
    } else if (messageType === 'ADD_PERIOD' || messageType === 'CREATE_PERIOD') {
      this.periodsHelper.addPeriods([payload as tables.IPeriodsItem]);
    }
  };

  public getDatasetTitleTemplate(route: string): string {
    const defaultTemplate: string = '%D';
    switch (route) {
      case '#dashboards':
        return this._configsHelper.getStringValue('header.Dashboard', defaultTemplate);
      case '#map'       :
        return this._configsHelper.getStringValue('header.Map', defaultTemplate);
      case '#trends':
        return this._configsHelper.getStringValue('header.Chart', defaultTemplate);
    }
    return defaultTemplate;
  }

  public getConfigHelper = () => this._configsHelper;

  public getBiQuery() {
    return this._biQuery;
  }

  public getDataProvider() {
    return this._biQuery.getDataProvider();
  }

  /*  order: config -> localization -> empty str */
  public getConfigParameter(key, defaultValue) {
    return this._configsHelper.getValue(key, lang(key, defaultValue)) || '';
  }

  public getEnterUrl(): IUrl {
    return this._configsHelper.getEnterUrl(this.guid);
  }

  public getPeriodInfoByRange(startId: string, endId: string, periodType?: number): IPeriodInfo {
    let start: IPeriod = $eid(this.periods, startId) || null;
    let end: IPeriod = $eid(this.periods, endId) || null;
    let type: number = this.periodsHelper.getDefaultPeriodType();

    if (periodType && String(periodType).match(/^(\d+)(?:\.(\d+))?$/)) {       // period-type set - the lower priority
      type = parseInt(RegExp.$1);

      // error handling: if no periods with such type, then autoselect other period type
      if (!this.periodsHelper.getPeriodsByTypeId(type).length) {
        type = this.periodsHelper.getDefaultPeriodType();
      }
    }

    if (start) {
      type = start.period_type;
    }
    if (end) {
      type = end.period_type;
    }       // end periodType has higher priority for conflicts

    const periodTypeFilter = (p: IPeriod) => p.period_type === type;
    let startFilter = (p) => true;
    let endFilter = (p) => true;

    if (start !== null) {
      const startDate: number = +start.date;
      startFilter = (p) => p.orderBy >= startDate;
    }
    if (end !== null) {
      const endDate: number = +end.date;
      endFilter = (p) => p.orderBy <= endDate;
    }

    const periods: IPeriod[] = this.periods.filter((p: IPeriod) => periodTypeFilter(p) && startFilter(p) && endFilter(p));
    markContinuousPeriodType(periods, [type, 1]);

    if (periods.length) {
      start = periods[0];
      end = periods[periods.length - 1];
    }

    return {
      type: type,
      startId: start ? start.period_id : null,
      endId: end ? end.period_id : null,
      start: start,
      end: end,
      startDate: start && start.startDate,
      endDate: end && end.startDate,
      periods: periods,
    };
  }

  public createVizelConfig(rawCfg: tables.IRawVizelConfig, view_class?: string): IVizelConfig {
    const cfg: IVizelConfig = new VizelConfig(this, rawCfg, view_class);
    // HACK
    cfg['normStrategy'] = this.getConfigHelper().getStringValue('norm.displayStyle', 'line');
    if (this._configsHelper.getBoolValue('vizels.*.options.DisplayAllBadges')) {
      cfg.addOption('DisplayAllBadges');
    }
    return cfg;
  }

  public getSerial(): moment.Moment {
    return this._serial;
  }
}
