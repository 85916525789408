import React from 'react';
import cn from 'classnames';

interface IOnlyWhenVisibleProps {
  className?: string;
  children: any;
  fallback?: () => any;
}


export class OnlyWhenVisible extends React.Component<IOnlyWhenVisibleProps> {
  private _observer: any = null;
  public state: { isShown: boolean } = {isShown: false};

  private _onSetupRef = (container): void => {
    if (container) {
      this._observer = new IntersectionObserver((arg: any[]) => {
        if (arg[0].isVisible || arg[0].isIntersecting) {
          this.setState({isShown: true});
        }
      }, {root: null});
      this._observer.observe(container);

    } else this._observer.disconnect();
  }

  public render() {
    const {className, children, fallback} = this.props;
    const {isShown} = this.state;

    if (isShown) return children;

    return (
      <div ref={this._onSetupRef} className={cn('OnlyWhenVisible', className)}>
        {typeof fallback === 'function' && fallback()}
      </div>);
  }
}
