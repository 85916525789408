import React from 'react';
import cn from 'classnames';
import './VirtualList.scss';
import throttle from 'lodash/throttle';
import FenwickTree from './FenwickTree';

interface IVirtualListItemProps {
  idx: number;
  item: any;
  top: number;
  renderItem: (item: any, idx: number) => any;
  onItemHeightChange: (idx: number, height: number) => any;
}

class VirtualListItem extends React.Component<IVirtualListItemProps> {
  private _itemHeight = null;
  private _ref = null;

  private _setupItemRef = (ref) => {
    this._ref = ref;
    if (!ref) return;
    let itemHeight = Math.ceil(ref.offsetHeight);
    // if (this._itemHeight !== itemHeight) {
    //   this._itemHeight = this._itemHeight
    this.props.onItemHeightChange(this.props.idx, itemHeight);
    // }
  };

  public componentDidUpdate(prevProps: Readonly<IVirtualListItemProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this._ref
      // && this._ref.offsetHeight !== this._itemHeight
      ) {
      this.props.onItemHeightChange(this.props.idx, this._ref.offsetHeight);
    }
  }

  public render() {
    const { idx, item, top, renderItem } = this.props;

    return (
      <li className="VirtualList__Item"
          data-idx={idx}
          style={{top, position: 'absolute'}}
          ref={this._setupItemRef}>
        {renderItem(item, idx)}
      </li>);
  }
}


interface IVirtualListProps {
  className?: string;
  items: {length: number};                                                                          // array-like
  renderItem: (item: any, idx: number) => any;
}

class VirtualList extends React.Component<IVirtualListProps> {
  public state: {
    height: number;
    from: number;
    to: number
  } = {
    height: 0,
    from: 0,
    to: 0,
  };
  private _ft: FenwickTree | null;
  private _container: HTMLUListElement | null = null;

  public constructor(props: IVirtualListProps) {
    super(props);
    this.state.to = props.items.length ? 1 : 0;
  }

  public componentDidUpdate(prevProps: Readonly<IVirtualListProps>, prevState: Readonly<{}>, snapshot?: any) {
    // debugger;
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Readonly<IVirtualListProps>, nextContext: any): void {
    if (this.props.items.length !== nextProps.items.length) {
      this._ft = null;
      this.setState({from: 0, to: nextProps.items.length ? 1 : 0});
      this._updateFromTo();
    }
  }

  private _onItemHeightChange = (idx: number, itemHeight: number) => {
    const { items } = this.props;
    if (!this._ft) {
      this._ft = new FenwickTree(items.length, itemHeight);
    } else {
      this._ft.set(idx, itemHeight);
    }

    const defaultSize = this._ft.sum(items.length) / items.length;                                  // средняя высота
    this._ft.setDefaultSize(defaultSize);                                                           // обновим ее в дереве Фенвика

    this._updateFromTo();
  }

  private _setupContainer = (container) => {
    this._container = container;
    this._updateFromTo();
  };

  // не будем вызывать изменение стейта высоты слишком часто
  private _setHeight = throttle((height) => {
    if (this.state.height !== height) {
      this.setState({height});
    }
  }, 333);

  private _updateFromTo = () => {
    const { items } = this.props;
    if (this._ft && this._container) {
      const { scrollTop, offsetHeight } = this._container;
      const from = Math.max(this._ft.idx(scrollTop - offsetHeight) - 1, 0);                         // 1 страницу вверх
      const to = Math.min(this._ft.idx(scrollTop + 2 * offsetHeight) + 2, items.length);            // и 1 вниз

      if (this.state.from !== from || this.state.to !== to) {
        this.setState({from, to});
      }

      const height = Math.ceil(this._ft.sum(items.length));
      this._setHeight(height);
    }
  };

  public render() {
    const { className, items, renderItem } = this.props;
    const { height, from, to } = this.state;

    let itemsToRender = [];
    for (let idx = from; idx < to; idx++) {
      const top = this._ft?.sum(idx) ?? 0;
      itemsToRender.push(
        <VirtualListItem idx={idx}
                         key={idx}
                         item={items[idx]}
                         top={top}
                         renderItem={renderItem}
                         onItemHeightChange={this._onItemHeightChange}/>);
    }

    // TODO: добавить отслеживание ресайза
    return (
      <article className={cn('VirtualList', className)}
               ref={this._setupContainer}
               onScroll={this._updateFromTo}>
        <ul className="VirtualList__List"
            style={{height: height || 0}}
            data-from={from}
            data-to={to}
            data-length={items.length}
          >

          {itemsToRender}

          {/*<div className="VirtualList__HeightPixel"*/}
          {/*     style={{top: (height || 0) - 1}}/>*/}
        </ul>
      </article>);
  }
}


export default VirtualList;
