//
// TODO: check null vectors, matrices with null-rows
//
export function vectorMin(vec: IValue[]): number {
  return Math.min.apply(Math, vec.filter((val) => typeof val === 'number') as number[]);
}
export function vectorMax(vec: IValue[]): number {
  return Math.max.apply(Math, vec.filter((val) => typeof val === 'number') as number[]);
}
export function matrixMin(mtx: IValue[][]): number {
  return Math.min.apply(Math, mtx.map(vectorMin));
}
export function matrixMax(mtx: IValue[][]): number {
  return Math.max.apply(Math, mtx.map(vectorMax));
}
export function cubeMin(cub: IValue[][][]): number {
  return Math.min.apply(Math, cub.map(matrixMin));
}
export function cubeMax(cub: IValue[][][]): number {
  return Math.max.apply(Math, cub.map(matrixMax));
}


export function sum(...args: number[]): number {
  let r: number = 0;
  for (let a of args) r += a;
  return r;
}

export function vectorSum(vec: number[]): number {
  let r: number = 0;
  for (let i = 0; i < vec.length; ++i) {
    r += vec[i];
  }
  return r;
}
export function matrixSum(mtx: number[][]): number {
  return vectorSum(mtx.map(vectorSum));
}
export function cubeSum(cub: number[][][]): number {
  return vectorSum(cub.map(matrixSum));
}

export function vectorSumNull(vec: number[]): number {
  let r: number = 0;
  for (let i = 0; i < vec.length; ++i) {
    if (vec[i] !== null) {
      r += vec[i];
    }
  }
  return r;
}

export function createConstVector<T>(v: T, x: number): T[] {
  const r: T[] = new Array(x);
  while (x--) r[x] = v;
  return r;
}
export function createConstMatrix<T>(v: T, y: number, x: number): T[][] {
  const r: T[][] = new Array(y);
  while (y--) r[y] = createConstVector(v, x);
  return r;
}
export function createConstCube<T>(v: T, z: number, y: number, x: number): T[][][] {
  const r: T[][][] = new Array(z);
  while (z--) r[z] = createConstMatrix(v, y, x);
  return r;
}

export function createNullVector<T>(x: number): T[] {
  return createConstVector(null, x);
}
export function createNullMatrix<T>(y: number, x: number): T[][] {
  return createConstMatrix(null, y, x);
}
export function createNullCube<T>(z: number, y: number, x: number): T[][][] {
  return createConstCube(null, z, y, x);
}


export const isNullValue: (val: IValue) => boolean = (val: IValue): boolean => val === null;
export const isNullVector: (vec: IValue[]) => boolean = (vec: IValue[]): boolean => vec.every(isNullValue);
export const isNullMatrix: (mtx: IValue[][]) => boolean = (mtx: IValue[][]): boolean => mtx.every(isNullVector);
export const isNullCube: (cub: IValue[][][]) => boolean = (cub: IValue[][][]): boolean => cub.every(isNullMatrix);


export function matrixTranspose<T>(mtx: T[][]): T[][] {
  const result: T[][] = [];
  if (mtx.length === 0) return [];
  const sizeI: number = mtx.length;
  const sizeK: number = mtx[0].length;
  for (let k = 0; k < sizeK; k++) {
    result.push([]);
    for (let i = 0; i < sizeI; i++) {
      result[k][i] = mtx[i][k];
    }
  }
  return result;
}


export function vectorHasNumericData(vec: IValue[]): boolean {
  for (let val of vec) {
    if (typeof val === 'number') {
      return true;
    }
  }
  return false;
}

export function matrixHasNumericData(mtx: IValue[][]): boolean {
  for (let vec of mtx) {
    if (vectorHasNumericData(vec)) {
      return true;
    }
  }
  return false;
}
