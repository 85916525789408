import {
  BaseEntitiesService,
  BaseService,
  createObjectsCache,
  createSingleton,
  IBaseEntities,
  repo
} from '@luxms/bi-core';
import { IBaseModel } from '@luxms/bi-core/dist/BaseService';
import { cloneDeep, throttle } from 'lodash';


export interface IDsVarsModel extends IBaseModel {
  dsVars: IVarRaws[];
}

export type VarRawType = 'INT' | 'FLOAT' | 'DATE' | 'DATETIME';

export interface IVarRaws {
  readonly id: number;
  readonly ident: string;
  readonly var_type: VarRawType;
  readonly default_value: string;
  readonly min_value: string;
  readonly max_value: string;
  readonly description: string;
  readonly config: any;
}

class DatasetVarsRepository extends repo.BaseRepository<IVarRaws> {
  public constructor(shemaName: string) {
    super(shemaName, 'vars');
  }
}

export class DatasetVarsService extends BaseEntitiesService<IVarRaws> {
  public static readonly MODEL: IBaseEntities<IVarRaws>;

  private constructor(shemaName: string) {
    super(new DatasetVarsRepository(shemaName));
  }

  private static _cache = createObjectsCache(id => new DatasetVarsService(String(id)), '__dsVarsService');
  public static createInstance: (id: string | number) => DatasetVarsService = DatasetVarsService._cache.get;

}


export class DsVarsService extends BaseService<IDsVarsModel> {
  private readonly _schemaName: string;
  private readonly _datasetVarsService: DatasetVarsService;

  private constructor(schemaName: string) {
    super({loading: true, error: null, dsVars: null});
    this._datasetVarsService = DatasetVarsService.createInstance(schemaName);
    this._datasetVarsService.subscribeUpdatesAndNotify(this._onSrvUpdate);
  }

  private _onSrvUpdate = (model: any): void => {
    if (model.loading) return;
    const dsVars: IVarRaws[] = model.vars.filter(v => v?.id);
    this._updateModel({loading: false, error: null, dsVars});
  }

  public _updateVar = throttle((ident: string, value: any): void => {
    const dsVarsCopy: any = cloneDeep(this._model.dsVars);
    const idx = dsVarsCopy.findIndex(v => v.ident === ident);
    if (idx < 0) return;
    dsVarsCopy[idx].default_value = value;
    this._updateModel({dsVars: [...dsVarsCopy]});
  }, 1000);

  private static _cache = createObjectsCache(id => new DsVarsService(String(id)), '__dsVarsService');
  public static createInstance: (schema_name: number | string) => DsVarsService = DsVarsService._cache.get;

  protected _dispose() {
    DsVarsService._cache.remove(this._schemaName);
    this._datasetVarsService.unsubscribe(this._onSrvUpdate);
    super._dispose();
  }
}

