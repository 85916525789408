import { BaseService } from '@luxms/bi-core';

export interface IAdmShellVM {
  loading?: boolean;
  error?: string;
  viewClassId: 'AdmShell';
}

export class AdmShellVC extends BaseService<IAdmShellVM> {
  public constructor() {
    super({viewClassId: 'AdmShell'});
  }
}
