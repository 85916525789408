import { BaseService, srv, repo, UrlState, IUrl } from '@luxms/bi-core';
import { IBaseModel } from '@luxms/bi-core/dist/BaseService';

import DashboardTopicsService = srv.ds.DashboardTopicsService;
import DashboardsService = srv.ds.DashboardsService;

import { $eid } from '../../libs/imdas/list';
import IRawDashboardTopic = repo.ds.IRawDashboardTopic;
import { IRawDataset } from '@luxms/bi-core/dist/repositories/adm';

export interface IDashboardsByTopicsMode extends IBaseModel {
  topics: IRawDashboardTopicMode[];
  dashboards: ILocalDataset[];
}

export interface IRawDashboardTopicMode extends IRawDashboardTopic {
  children: ILocalDataset[];
}

export interface ILocalDataset extends IRawDataset {
  href: string;
}

export class DashboardByTopicsService extends BaseService<IDashboardsByTopicsMode> {
  private readonly _shemaName: string;
  private readonly _dashboardTopicsService: DashboardTopicsService;
  public readonly _dashboardsService: DashboardsService;

  public constructor(shemaName: string) {
    super({
      loading: true,
      error: null,
      topics: [],
      dashboards: [],
    });
    this._shemaName = shemaName;

    this._dashboardTopicsService = DashboardTopicsService.createInstance(shemaName);
    this._dashboardsService = DashboardsService.createInstance(shemaName);
    this._dashboardTopicsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
    this._dashboardsService.subscribeUpdatesAndNotify(this._onServiceUpdate);
  }

  private _onServiceUpdate = (): void => {
    const modelDashboards = this._dashboardsService.getModel();
    const modelTopics = this._dashboardTopicsService.getModel();
    if (modelTopics.loading || modelDashboards.loading) {
      this._updateWithLoading();
      return;
    }

    if (modelDashboards.error || modelTopics.error) {
      this._updateWithError(modelTopics.error || modelDashboards.error);
      return;
    }
    const url: IUrl = UrlState.getInstance().getModel();
    const dashboardsRoute = (url.route === '#dashboards' || url.route === '#dbuilder' || url.route === '#edt-dashboards') ? url.route : '#dashboards';

    const topics = modelTopics.map((topic) => ({...topic, children: []}));
    const dashboards = modelDashboards.map((rawDashboard) => ({
      ...rawDashboard,
      href: UrlState.getInstance().buildUrl({
        segment: 'ds',
        segmentId: this._shemaName,
        route: dashboardsRoute,
        dboard: rawDashboard.id
      }),
    }));

    dashboards.forEach((dashboard) => {
      const topicId = dashboard.topic_id;
      const topic = $eid(topics, topicId);
      if (topic) topic.children.push(dashboard);
    });

    this._updateWithData({topics, dashboards, loading: false});
  }

  protected _dispose() {
    this._dashboardTopicsService.unsubscribe(this._onServiceUpdate);
    this._dashboardsService.unsubscribe(this._onServiceUpdate);
    super._dispose();
  }
}