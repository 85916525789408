import React from 'react';
import './OpenModalContainer.scss';
import cn from 'classnames';
import {IOpenModalVMOpt, OpenModalVC} from '../../view-controllers/OpenModalVC';

interface IOpenModalContainerProp {
  children: React.ReactElement | null;
  options: IOpenModalVMOpt;
}

export class OpenModalContainer extends React.Component<IOpenModalContainerProp, any> {
  public constructor(props: IOpenModalContainerProp) {
    super(props);
  }

  public componentDidMount() {
    window.addEventListener('keydown', this._keyPress);
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this._keyPress);
  }

  private _keyPress = (e: KeyboardEvent): void => {
    if (!this.props.children) return;
    if (e.keyCode === 27) this._onModalCancel();
  }

  private _onModalCancel = (args?: any): void => OpenModalVC.getInstance().onModalCancel(args);
  private _onModalResult = (args?: any): void => OpenModalVC.getInstance().onModalResult(args);


  public render() {
    if (!this.props.children) return null;
    const {options = {}} = this.props;

    const style = options?.style ?? {};
    const hiddenWrapper = options?.hiddenWrapper;
    const cancelWrapper = options?.cancelWrapper === false;

    return (
      <div className="OpenModalContainer">
        <div onClick={cancelWrapper ? null : this._onModalCancel}
             className={cn('OpenModalContainer_Wrapper-Hidden', {hidden: !!(hiddenWrapper)})}/>
        <div className={cn('OpenModalContainer__Content', options?.className)} style={{...style}}>
          {React.cloneElement(this.props.children, {
            onModalCancel: (args: any) => this._onModalCancel(args),
            onModalResult: (args: any) => this._onModalResult(args)
          })}
        </div>
      </div>
    );
  }
}

export default OpenModalContainer;