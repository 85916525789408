import React from 'react';

const __iconsCache: {[id: string]: Promise<string>} = {};

export function loadIconContent(iconId: string): Promise<string> {
  if (!iconId) {
    return Promise.reject('NO_ICON_ID');
  }
  if (!(iconId in __iconsCache)) {
    __iconsCache[iconId] = fetch(`assets/icons/${iconId}.svg`)
      .then(response => response.text())
      .catch(err => ICON_404);
  }
  return __iconsCache[iconId];
}


const ICON_404 =
  `<?xml version="1.0" encoding="utf-8"?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="121 242 270 270" enable-background="new 121 242 270 270" xml:space="preserve">
    <g>
      <path d="M256,242c-74.562,0-135,60.438-135,135c0,74.562,60.438,135,135,135c74.562,0,135-60.438,135-135
        C391,302.438,330.562,242,256,242z M256,490.906c-62.91,0-113.906-50.995-113.906-113.906c0-62.91,50.996-113.906,113.906-113.906
        S369.906,314.09,369.906,377C369.906,439.911,318.91,490.906,256,490.906z"/>
      <polygon points="202.016,408.052 201.291,426.791 217.52,427.419 218.245,408.679 225.918,408.978 226.49,394.222 225.418,394.181
        171.658,406.878 	"/>
      <path d="M265.212,405.474c-0.286,7.377-3.641,10.055-8.805,9.855c-5.164-0.199-8.301-3.13-8.016-10.506l0.628-16.216l-16.404,3.874
        l-0.413,10.68c-0.64,16.526,7.7,26.305,23.634,26.922c15.935,0.618,25.005-8.488,25.645-25.014l0.942-24.35l-16.403,3.873
        L265.212,405.474z"/>
      <polygon points="320.451,371.739 319.443,397.82 303.36,397.199 314.609,373.119 297.601,377.135 288.457,396.621 287.887,411.376
        318.871,412.575 318.146,431.312 334.375,431.942 335.1,413.203 342.774,413.501 343.344,398.746 335.671,398.449 336.856,367.866
          "/>
      <path d="M207.778,319.867l-17.707,0.641l-26.986,70.864l0.534,14.755l0.626-0.022l52.661-16.681l-6.602,0.239L207.778,319.867z
         M194.072,390.25l-16.084,0.582l14.701-38.8L194.072,390.25z"/>
      <path d="M246.1,317.3c-15.936,0.575-24.3,10.335-23.703,26.861l1.557,43.03l16.069-5.09l-1.432-39.561
        c-0.267-7.379,2.878-10.299,8.042-10.485c5.165-0.188,8.513,2.498,8.779,9.876l1.263,34.896l16.069-5.09l-1.062-29.359
        C271.084,325.851,262.036,316.721,246.1,317.3z"/>
      <polygon points="309.556,347.804 309.994,359.938 326.063,354.847 324.644,315.638 306.938,316.28 287.612,367.028
        304.271,361.751 	"/>
    </g>
  </svg>`;


interface IBIIconProps {
  icon?: string;
  text?: string;
  className?: string;
  href?: string;                // if set, then <a>
  style?: any;
  onPress?: any;
  hint?: string;
  shape?: string;               // circle
  onDragOver?: any;
  onDragLeave?: any;
  onDrop?: any;
  target?: string;
}

export class BIIcon extends React.PureComponent<IBIIconProps> {
  public props: IBIIconProps;
  public state: {
    iconId: string;
    icon: string;
  };

  private _mounted: boolean = false;

  public constructor(props) {
    super(props);
    let iconId: string = '';
    let icon: string = '';
    if (props.icon && props.icon[0] !== '<') {
      iconId = props.icon;
      icon = '';
    } else {
      iconId = '';
      icon = props.icon;
    }
    this.state = {iconId, icon};
    this._loadIconContent(iconId);
  }

  public componentDidMount(): void {
    this._mounted = true;
  }

  public componentWillUnmount() {
    this._mounted = false;
  }

  public UNSAFE_componentWillReceiveProps(props: IBIIconProps) {
    if (props.icon !== this.props.icon) {

      let iconId: string = '';
      let icon: string = '';

      if (props.icon && props.icon[0] !== '<') {
        iconId = props.icon;
        icon = '';
      } else {
        iconId = '';
        icon = props.icon;
      }
      this.setState({icon, iconId});
      this._loadIconContent(iconId);
    }
  }

  private _loadIconContent(iconId: string) {
    loadIconContent(iconId)
      .then(
        (svgText: string) => {
          // if (this.state.iconId !== iconId) {         // icon changed!
          //   return;
          // }
          if (this._mounted) {
            this.setState({
              iconId,
              icon: svgText,
            });
          } else {
            this.state.iconId = iconId;
            this.state.icon = svgText;
          }
        },
        (err) => {
          // ...
        });
  }

  public render(): JSX.Element {
    const {href, text, onPress, hint, shape, onDragOver, onDragLeave, onDrop, target} = this.props;
    const {icon, iconId} = this.state;
    const className = [(this.props.className || ''), iconId, 'bi-icon'].join(' ');
    const style = this.props.style || {};
    if (!onPress && !href && !style.cursor) {
      style.cursor = 'auto';
    }

    if (href) {
      if (text) {
        return (
          <a href={this.props.href}
             title={hint}
             target={target}
             className={className}
             style={style}
             onClick={onPress}
             onDragOver={onDragOver}
             onDragLeave={onDragLeave}
             onDrop={onDrop}>
            <div dangerouslySetInnerHTML={{__html: icon}} style={{height: '100%'}}/>
            <span className="title">{text || ''}</span>
          </a>);
      } else {
        return (
          <a href={href}
             title={hint}
             target={target}
             className={className}
             style={style}
             dangerouslySetInnerHTML={{__html: icon}}
             onClick={onPress}
             onDragOver={onDragOver}
             onDragLeave={onDragLeave}
             onDrop={onDrop}/>);
      }
    } else {   // no href
      if (text) {
        return (
          <span className={className}
                style={style}
                onClick={onPress}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                title={hint}
                onDrop={onDrop}>
            <div dangerouslySetInnerHTML={{__html: icon}} style={{height: '100%'}}/>
            <span className="title">{text || ''}</span>
          </span>);
      } else {
        return (
          <span className={className}
                style={style}
                title={hint}
                dangerouslySetInnerHTML={{__html: icon}}
                onClick={onPress}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}/>);
      }
    }
  }
}
