import * as React from 'react';
import cn from 'classnames';
import { IHrefButtonVM, IMainToolbarVM } from '../../../view-controllers/panels/MainToolbarVC';
import { lang } from '../../../utils/utils';
import './MainToolbar.scss';
import { BIIcon } from '../../components/BIIcon/BIIcon';
import { useServiceItself } from '../../useService';
import EditModeVC from '../../../view-controllers/EditModeVC';
import {IThemeVM, ThemeVC} from '../../../view-controllers/ThemeVC';
import {UrlState, urlState} from '@luxms/bi-core';
import _default from '@luxms/bi-face/store/themes';
import light = _default.light;
import AccountTool from '@luxms/bi-face/AccountTool';
import ActionList from '../../../../srx/components/action/ActionList';
import SVGIcon from '../../components/SVGIcon';
const skin: any = require('../../../skins/skin.json');

//
// Views
//
export class HrefButton extends React.PureComponent<IHrefButtonVM> {
  public props: IHrefButtonVM;

  public render() {
    const {active, href, icon, title, visible, target} = this.props;

    if (!visible) {
      return null;
    }

    return (
      <BIIcon className={cn({active})}
              icon={icon}
              href={href}
              target={target}
              hint={title}/>);
  }
}


export const MainToolbar__EditModeButton: React.FC<{ schema_name?: string }> = ({schema_name}): JSX.Element => {
  const editModeVC = useServiceItself<EditModeVC>(EditModeVC);
  const editMode = editModeVC.getModel();

  if (editMode.error || editMode.loading || !editMode.enabled) return null;


  const onClick = (): void => {
    if (editMode.editMode) editModeVC.reset();
    else editModeVC.enterEditMode();
    if (urlState.getModel().dash !== undefined) UrlState.getInstance().updateModel({dash: null});
  };

  return (
    <BIIcon icon="toolbar/edit-mode"
            className={cn({active: editMode.editMode})}
            onPress={onClick}
            hint={lang('edit_mode')}/>);
};

export const MainToolbar__ThemeSwitchButton: React.FC<any> = ({className}): JSX.Element => {
  const themeVC: ThemeVC = useServiceItself(ThemeVC);
  const themeMode: IThemeVM = themeVC.getModel();
  const themes = Object.keys(themeMode.themes);
  if (themeMode.error || themeMode.loading || themes.length < 2) return null;

  const onClick = () => {
    if (themeMode.currentThemeId === 'light') {
      themeVC.setTheme('dark');
      return;
    }
    themeVC.setTheme('light');
  };

  return (themes.length === 2 ? <BIIcon className={className} icon={'toolbar/theme-' + themeMode.currentThemeId} onPress={onClick} hint={lang('switch_theme')} /> :
    <AccountTool className={cn('MainToolbar__Select bi-icon', className)}
                 icon1={<SVGIcon path={require('./theme.svg')}/>}
                 menu={
                   <ActionList onClick={(theme) => themeVC.setTheme(theme)} className="AccountList">
                     {themes.map((theme, i) => <ActionList.Action key={theme}
                                                             className={cn('AccountList__Item', {'Active': themeMode.currentThemeId === theme})}>{themeMode.themes[i]?.title ?? theme}</ActionList.Action>)}
                   </ActionList>
                 }/>
  );
  // return (
  //   <BIIcon icon={'toolbar/theme-' + themeMode.currentThemeId} onPress={onClick} hint={lang('switch_theme')} />);
};


export class MainToolbar extends React.PureComponent<IMainToolbarVM> {
  public props: IMainToolbarVM;

  public constructor(props: IMainToolbarVM) {
    super(props);
  }

  private _onToggleChartType = () => {
    if (this.props.onToggleChartType) {
      this.props.onToggleChartType();
    }
  }

  public render() {
    const {loading, trendsButton, mapButton, dashboardsButton, isAtHome, userHomeEnabled, toUserHomeUrl, route, autoScale, pluginButtons, hasMapHomeIcon, currentChartType, nextChartType, mapMetricsPanelVisible} = this.props;
    const {onToggleChartType, onPressShowFullMap, onToggleAutoscale, onToggleMapMetricsPanelVisible, schemaName} = this.props;
    const showFullMainToolbar = skin.hasOwnProperty('hasFullMainToolbar') ? skin['hasFullMainToolbar'] : false;
    if (loading) {
      return (
        <article className="MainToolbar loading"></article>);
    }

    return (
      <article className="MainToolbar view panels toolbar">

        <MainToolbar__EditModeButton schema_name={schemaName}/>
        <MainToolbar__ThemeSwitchButton/>

        {!!trendsButton && showFullMainToolbar &&
          <HrefButton {...trendsButton} />}

        {!!mapButton && showFullMainToolbar &&
          <HrefButton {...mapButton} />}

        {!!dashboardsButton && showFullMainToolbar &&
          <HrefButton {...dashboardsButton}/>}

        {/* dashboard */}
        {!!userHomeEnabled &&
        <BIIcon className={cn({active: isAtHome})}
                icon="toolbar/user-home"
                href={toUserHomeUrl}
                hint={lang('go_to_home')}/>}

        {/*- delimiter */}
        {/*<div className="MainToolbar__Delimiter" />*/}
        <BIIcon icon="info2"
                className="toolbar_docs"
                href="/docs/"
                target={'_blank'}
                hint={'Документация'} />

        {route === '#trends' &&
        <BIIcon icon="toolbar/ruler"
                className={cn({active: autoScale})}
                onPress={onToggleAutoscale}
                hint={lang('overall_scale')} />}

        { route === '#map' && hasMapHomeIcon &&
        <BIIcon icon="toolbar/home"
                onPress={onPressShowFullMap}
                href="javascript:void(0)"
                hint={lang('show_full_map')}/>}

        { route === '#map' &&
        <BIIcon icon="toolbar/map-metrics"
                className={cn({active: mapMetricsPanelVisible})}
                onPress={onToggleMapMetricsPanelVisible}
                hint={lang('map-metric-legend')} /> }

        { route === '#map' && !!currentChartType &&
        <BIIcon icon={'toolbar/' + nextChartType}
                onPress={this._onToggleChartType}
                hint={lang('map_set_chart_type_' + nextChartType)} />}

        {/* plugins */}
        {pluginButtons.map(pluginButton =>
          pluginButton.icon ?
            <BIIcon key={pluginButton.title}
                    className={cn('MainToolbar__Button', pluginButton.className, {active: pluginButton.active})}
                    href={pluginButton.href || 'javascript:void(0)'}
                    icon={pluginButton.icon}
                    hint={pluginButton.title}
                    onPress={pluginButton.onPress}/>
            :
            <div data-bind="attr:{title: $data.title, class: $data.className}, anchorClick: $data.onPress, css:{active:$data.active}">
            </div>)}

      </article>);
  }
}
