exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.MainToolbar{display:flex;flex-direction:row-reverse;margin-top:2px}.MainToolbar__Button{width:42px;height:42px}.MainToolbar__Delimiter{width:20px;height:4px;float:right}.MainToolbar__Select.bi-icon{display:inline-flex;min-width:3rem;padding:0}.MainToolbar__Select.bi-icon div:first-child{width:2rem;height:2rem}.MainToolbar__Select.bi-icon div:last-child{position:absolute;width:1rem;height:1rem;right:.2rem}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};