import React from 'react';

import Breadcrumb from '@luxms/bi-face/Breadcrumb';

import {IUrl, srv, UrlState} from '@luxms/bi-core';
import {lang} from '../../utils/utils';
import {$eid} from '../../libs/imdas/list';
import buildUrl from '../../utils/buildUrl';
import {OptionsProvider} from '../../config/OptionsProvider';


import DatasetsService = srv.adm.DatasetsService;
import DatasetsTopicsService = srv.adm.TopicsService;
import DashboardsService = srv.ds.DashboardsService;
import DashboardTopicsService = srv.ds.DashboardTopicsService;
import DashletsService = srv.ds.DashletsService;


interface IBreadcrumbItem {
  id: string;
  title: string;
  url?: string;
}

interface IBreadcrumbControlState {
  list: IBreadcrumbItem[];
  path: string[];
  shemaName: string;
  dboard: string | number;
  dash: string;
  topic: number;
}

export class BreadcrumbControl extends React.Component<any, IBreadcrumbControlState> {
  private readonly _urlState: UrlState;
  private readonly _datasetsTopicsService: DatasetsTopicsService;
  private readonly _datasetsService: DatasetsService;
  private _dashboardsService: DashboardsService;
  private _dashletsService: DashletsService;
  private _dashboardTopicsService: DashboardTopicsService;

  public state: IBreadcrumbControlState;

  public constructor(props) {
    super(props);
    this._urlState = UrlState.getInstance();
    this._datasetsTopicsService = DatasetsTopicsService.getInstance();
    this._datasetsService = DatasetsService.getInstance();

    this.state = {list: [], path: [], shemaName: null, dboard: null, dash: null, topic: null};
  }

  public componentDidMount() {
    this._urlState.subscribeUpdatesAndNotify(this._onUpdateModel);
    this._datasetsTopicsService.subscribeUpdatesAndNotify(this._onUpdateModel);
    this._datasetsService.subscribeUpdatesAndNotify(this._onUpdateModel);
  }

  public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IBreadcrumbControlState>) {
    if (prevState.shemaName !== this.state.shemaName) {
      if (this._dashboardsService) this._dashboardsService.unsubscribe(this._onUpdateModel);
      if (this._dashletsService) this._dashletsService.unsubscribe(this._onUpdateModel);
      if (this._dashboardTopicsService) this._dashboardTopicsService.unsubscribe(this._onUpdateModel);

      this._dashboardsService = DashboardsService.createInstance(this.state.shemaName);
      this._dashboardsService.subscribeUpdatesAndNotify(this._onUpdateModel);

      this._dashletsService = DashletsService.createInstance(this.state.shemaName);
      this._dashletsService.subscribeUpdatesAndNotify(this._onUpdateModel);

      this._dashboardTopicsService = DashboardTopicsService.createInstance(this.state.shemaName);
      this._dashboardTopicsService.subscribeUpdatesAndNotify(this._onUpdateModel);
    }
  }

  public componentWillUnmount() {
    this._urlState.unsubscribe(this._onUpdateModel);
    this._datasetsTopicsService.unsubscribe(this._onUpdateModel);
    this._datasetsService.unsubscribe(this._onUpdateModel);
    if (this._dashboardsService) this._dashboardsService.unsubscribe(this._onUpdateModel);
    if (this._dashletsService) this._dashletsService.unsubscribe(this._onUpdateModel);
  }

  private _onUpdateModel = (): void => {
    const modelUrl: IUrl = this._urlState.getModel();
    const modelDatasetTopic = this._datasetsTopicsService.getModel();
    const modelDataset = this._datasetsService.getModel();
    const modelDashboard = this._dashboardsService?.getModel();             // могут быть пустым
    const modelDashlet = this._dashletsService?.getModel();                 // могут быть пустым
    const modelDashboardTopic = this._dashboardTopicsService?.getModel();   // могут быть пустым

    if (modelUrl.loading || modelDatasetTopic.loading || modelDataset.loading || modelDashboard?.loading || modelDashlet?.loading || modelDashboardTopic?.loading) {
      return;
    }

    let shemaName = null;
    if (/ds/.test(modelUrl.segmentId)) shemaName = modelUrl.segmentId;

    const topic: any = this._getTopicId();
    const dboard = this._getDashboardId();
    const dash = modelUrl?.dash ?? null;
    const path = [];

    (modelUrl?.path || []).forEach((key) => {
      if (key === 'dashboards' && topic) path.push('topic');
      path.push(key);
      if (key === 'dashboards' && modelUrl.dash) path.push('dash');
    });
    this.setState({path, shemaName, dboard, dash, topic}, () => this._createBreadcrumbList());
  }

  // == start получаю title из разных мест
  private _getDatasetTopicTitle = (val: string): string => {
    const modelTopic = this._datasetsTopicsService.getModel();
    const id = val.replace(/@_/, '');
    const myId = id ? Number(id) : val;
    return $eid(modelTopic, Number(myId))?.title ?? val;
  }
  private _getDashBoardTopicTitle = (): string => {
    const {topic} = this.state;
    const topics = this._dashboardTopicsService?.getModel() ?? [];
    return $eid(topics, topic)?.title ?? 'topic';
  }
  private _getDatasetTitle = (val: string): string => {
    const model = this._datasetsService.getModel();
    return model.find(m => m?.schema_name === val)?.title ?? val;
  }
  private _getDashboardTitle = (val: string): string => {
    const {dboard} = this.state;
    const dashboards = this._dashboardsService?.getModel() ?? [];
    return $eid(dashboards, Number(dboard))?.title;
  }
  private _getDashTitle = (): string => {
    const {dash} = this.state;
    const model = this._dashletsService.getModel();
    return $eid(model, Number(dash))?.title ?? 'dash';
  }
  // == end получаю title из разных мест
  private _getDashboardId = (): string | number => {
    const dboard = this._urlState.getModel()?.dboard ?? null;
    const dboards = this._dashboardsService?.getModel() ?? null;
    if (dboard || !dboards) return dboard;
    const dashboards = dboards.filter(d => d?.id !== undefined);
    const visibleDashboards = dashboards.filter(rawDashboard => !new OptionsProvider(rawDashboard.config.options).hasAnyOf('Hidden', 'TabHidden'));
    return visibleDashboards[0]?.id ?? null;
  }
  private _getTopicId = (): string | number => {
    const dbId = this._getDashboardId();
    const topics = this._dashboardTopicsService?.getModel() ?? null;
    const dboards = this._dashboardsService?.getModel() ?? null;
    if (!dbId || !topics) return null;
    const topicId = $eid(dboards, Number(dbId))?.topic_id ?? null;
    const topic = $eid(topics, topicId);
    if (topic?.title === 'R o o t') return null;
    return topic?.id ?? null;
  }

  private _createBreadcrumbList = (): void => {
    const {path} = this.state;
    const list: any = path.map((item, i) => this._getBreadcrumb(item)).filter(Boolean);

    this.setState({list});
  }

  private _getBreadcrumb(value: string): { id: string, title: string } {
    const regex1 = /presentations|tasks-from|tasks-self|chats|map/;
    switch (value) {
      case 'ds':
        return {id: value, title: lang('datasets')};
      case 'trends':
        return {id: value, title: lang('go_to_charts')};
      case 'dashboards':
        return {id: value, title: this._getDashboardTitle(value)};
      case 'edt-dashboards':
        return {id: value, title: this._getDashboardTitle(value)};
      case 'dash':
        return {id: value, title: this._getDashTitle()};
      case 'topic':
        return {id: value, title: this._getDashBoardTopicTitle()};
    }
    switch (true) {
      case regex1.test(value):
        return {id: value, title: lang(value)};
      case /@_/.test(value) :
        return {id: value, title: this._getDatasetTopicTitle(value)};
      case /ds_/.test(value):
        return {id: value, title: this._getDatasetTitle(value)};
    }

    return null;
  }

  private _onClick = (item: IBreadcrumbItem, idx, list: IBreadcrumbItem[]): void => {
    const copyList = list.slice(0);
    let dash = this.state.dash;
    let dboard = this.state.dboard;
    if (/ds_/.test(item.id)) {
      const dataset = this._datasetsService.getModel().find(m => m?.schema_name === item.id);
      const href = buildUrl(dataset.ui_cfg, dataset.schema_name);
      const a: HTMLAnchorElement = document.createElement('a');
      a.setAttribute('href', href);
      a.click();
      a.remove();
      return;
    }

    if (item.id === 'topic') {
      const modelDashboards = this._dashboardsService?.getModel() ?? null;
      const tIid = this._getTopicId();
      const dashboards = modelDashboards.filter(d => d.topic_id === Number(tIid));
      const findDashboards = dashboards.filter(rawDashboard => !new OptionsProvider(rawDashboard.config.options).hasAnyOf('Hidden', 'TabHidden'));
      dboard = findDashboards[0]?.id ?? null;
      copyList.push({id: 'dashboards', title: ''});
    }

    if (item.id !== 'dash') dash = null;
    const path = copyList.map(l => l.id).filter(f => f !== 'topic' && f !== 'dash');
    this._urlState.navigate({path, dash, dboard});
  }

  public render() {
    return <Breadcrumb list={this.state.list} onClickPath={this._onClick}/>;
  }
}

