exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.Vizel{width:100%;height:100%;overflow:visible}.Vizel>div{overflow:visible !important}.Vizel svg{overflow:visible}.Vizel.loading>*{opacity:.7}.Vizel.loading.table table,.Vizel.loading.table1d table{opacity:.7}.Vizel.loading.scales>div{opacity:.7}.Vizel.loading.text>.no-data{display:none}.Vizel.loading.text>.inner-table{opacity:.7}.Vizel.loading.list-item>table{opacity:.7}.Vizel.loading.image>img{opacity:.25}.Vizel.loading>.highcharts-container{opacity:.7}.Vizel.loading .no-data-label{display:none}.Vizel.error{background:repeating-linear-gradient(-45deg, #ffcdd2, #ffcdd2 10px, #ffdede 10px, #ffdede 20px)}.Vizel.no-data>.highcharts-container>svg>g,.Vizel.no-data>.highcharts-container>svg>*{opacity:.5}.Vizel.no-data>.highcharts-container>svg>g.highcharts-legend{opacity:1}.Vizel.no-data.circle>.highcharts-container>svg>g,.Vizel.no-data.circle>.highcharts-container>svg>*{opacity:.2}.Vizel.no-data.table>header{opacity:.5}.Vizel.no-data.table>section{opacity:.5}.Vizel.no-data.table>footer{display:none}.Vizel.no-data.table1d table{opacity:.5}.Vizel.no-data::after{content:\"No data\";position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);font-size:19px}html.ru .Vizel.no-data::after{content:\"\\41D\\435\\442   \\434\\430\\43D\\43D\\44B\\445\"}.Vizel.no-data.compare-sort::after{content:\"No detailed data avaliable\"}html.ru .Vizel.no-data.compare-sort::after{content:\"\\41D\\435\\442   \\434\\435\\442\\430\\43B\\44C\\43D\\44B\\445   \\434\\430\\43D\\43D\\44B\\445\"}.Vizel.disabled{-webkit-filter:grayscale(100%);filter:grayscale(100%)}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};