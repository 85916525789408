/**
 *   DlgShareWithUser component
 *   Shows dialog with users, allow search, select users
 *    displays error, loading
 *
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { WpLoadingIcon } from '../../components';
import { User } from '../../../services/adm/UsersService';
import { search } from '../../../utils/utils';
import { extractErrorMessage } from '@luxms/bi-core';
import './DlgShareWithUser.scss';
import { BIIcon } from '../../components/BIIcon/BIIcon';

const ShareDialogError = ({error}: IHasError) => (
  <p style={{fontSize: '14px', marginLeft: 20, marginTop: 30, color: 'red'}}>
    {error}
  </p>);


export interface IDlgShareWithUserProps {
  onClosePress: () => void;
  share: (user: User) => Promise<any>;
  title: string;
  noUsersError: string;
  users: User[];
  selectedUsers?: User[];
  loading: boolean;
  error: string;
}

export class DlgShareWithUser extends React.Component<IDlgShareWithUserProps> {
  public props: IDlgShareWithUserProps;
  public state: {
    searchTerm: string;
    error: string;
  };

  constructor(props: IDlgShareWithUserProps) {
    super(props);
    this.state = {
      searchTerm: '',
      error: null,
    };
  }

  private _applySearch = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  private async _applyShare(user: User) {
    if (this._isUserActive(user)) return;                                       // ignore click on active user
    try {
      await this.props.share(user);
      this.props.onClosePress();
    } catch (err) {
      console.error(err);
      this.setState({error: extractErrorMessage(err)});
    }
  }

  private _isUserActive (user: User): boolean {
    const {selectedUsers} = this.props;
    if (!selectedUsers) return false;
    return selectedUsers.indexOf(user) !== -1;
  }

  public render() {
    const {loading, users, onClosePress, title} = this.props;
    const {searchTerm} = this.state;
    let error: string = this.props.error || this.state.error;

    if (!error && !loading && users.length === 0) {
      error = this.props.noUsersError;
    }

    const usersAfterSearch: User[] = users.filter((user: User) => search(user.title, searchTerm));

    return (
      <section className="DlgShareWithUser ug dlg"
               data-bind="click:function(vm,event){event.stopPropagation(); return false;}, hideOnEscape: $data.handleClose">
        <header className="DlgShareWithUser__Header">
          <BIIcon className="folder icon black"
                  icon="user2"
                  style={{width: 40, height: 40}}/>
          <h1>{title}</h1>
          <BIIcon className="dark close"
                  icon="x"
                  style={{position: 'absolute', right: 0, top: 0, width: 40, height: 40}}
                  onPress={onClosePress}/>
        </header>
        <section className="DlgShareWithUser__Body body">
          {error &&
          <ShareDialogError error={error}/>}

          {loading && (<span className="magic-center" style={{width: '200px', top: 19}}>
            <WpLoadingIcon/>
          </span>)}

          {!error &&
          <input type="search"
                 value={searchTerm}
                 onChange={this._applySearch}/>}

          {!error &&
          <ul className="DlgShareWithUser__UsersList">
            {usersAfterSearch.map(user =>
              (<li key={user.id}
                   className={cn('DlgShareWithUser__UsersList__User', {active: this._isUserActive(user)})}
                   onClick={() => this._applyShare(user)}>{user.title}</li>))}
          </ul>}

        </section>
      </section>);
  }
}
