/**
 *
 *  This View Controller is implemented as singleton
 *   as it should be the one for the whole app
 *   and must be accessible from everywhere
 *
 */

import { BaseService, disposeAll, createSingleton } from '@luxms/bi-core';


export interface IPopupMenuItem {
  title: string;
  onPress: () => void;
}


export interface IPopupPosition {
  left?: number;
  top?: number;
  right?: number;
}


export interface IPopupVM extends IPopupPosition {
  loading?: boolean;
  error?: string;
  visible: boolean;
  // content
  dialogVM: any;
  description: string;
  menuItems: IPopupMenuItem[];
  // events
  onClose: () => void;
}


export class PopupVC extends BaseService<IPopupVM> {
  private _dlgVC: any = null;
  private _dlgOnCloseHandlers: any[] = [];
  private _dlgSubscriptions: IDisposable[] = [];
  private state: {
    toggleDescription: boolean,
  };

  public constructor() {
    super({
      loading: false,
      error: null,
      visible: false,
      dialogVM: null,
      description: null,
      menuItems: null,
      onClose: () => this._onClose(),
    });
    this.state = {
      toggleDescription: true,
    }
  }

  public showDialog(dlgVC: any, position: IPopupPosition, onClose: any = null): void {
    if (this._dlgVC === dlgVC) {
      if (onClose) {
        this._dlgOnCloseHandlers.push(onClose);
      }
      return;
    }
    this._disposeCurrentDialog();

    this._dlgVC = dlgVC;
    this._dlgVC.retain();

    if (onClose) {                                                                                  // add onClose listener
      this._dlgOnCloseHandlers.push(onClose);
    }

    this._dlgSubscriptions.push(this._dlgVC.subscribe('close', () => {        // inner dialog can send 'close' to close itself
      this.hideDialog(dlgVC);
    }));

    this._dlgSubscriptions.push(this._dlgVC.subscribeUpdatesAndNotify((dialogVM) => {
      if (this._dlgVC !== dlgVC) {
        return;
      }
      this._updateModel({
        left: position.left,
        top: position.top,
        right: position.right,
        visible: true,
        dialogVM,
        description: null,
        menuItems: null,
      });
    }));
  }

  public hideDialog(dlgVC: any): void {
    if (this._dlgVC === dlgVC) {
      this._onClose();
    }
  }

  public showDescription(description: string, position: IPopupPosition): void {
    this._disposeCurrentDialog();
    this._updateModel({
      left: position.left,
      right: position.right,
      top: position.top,
      visible: true,
      dialogVM: null,
      description,
      menuItems: null,
    });
  }

  public toggleDescription(description: string, position: IPopupPosition): void {
    if (this.state.toggleDescription) {
      this.showDescription(description, position);
      this.state.toggleDescription = false;
    } else {
      this._onClose();
      this.state.toggleDescription = true;
    }
  }

  public showContextMenu(menuItems: IPopupMenuItem[], position: IPopupPosition): void {
    this._disposeCurrentDialog();
    this._updateModel({
      left: position.left,
      right: position.right,
      top: position.top,
      visible: true,
      dialogVM: null,
      description: null,
      menuItems,
    });
  }

  private _disposeCurrentDialog() {
    if (!this._dlgVC) {
      return;
    }
    this._dlgOnCloseHandlers.forEach(handler => {
      try {
        handler();
      } catch (err) {
        console.error(err);
      }
    });
    this._dlgOnCloseHandlers = [];
    disposeAll(this._dlgSubscriptions);
    this._dlgVC.release();
    this._dlgVC = null;
  }

  private _onClose() {
    this._disposeCurrentDialog();
    this._updateModel({
      visible: false,
      dialogVM: null,
      description: null,
      menuItems: null,
    });
    this.state.toggleDescription = true;
  }

  public static getInstance: () => PopupVC = createSingleton<PopupVC>(() => new PopupVC());

  //
  // static helpers
  //
  public static hideDialog(dlgVC: any): void {
    return this.getInstance().hideDialog(dlgVC);
  }

  public static showDialog(dlgVC: any, position: IPopupPosition, onClose: any = null): void {
    return this.getInstance().showDialog(dlgVC, position, onClose);
  }

  public static showContextMenu(menuItems: IPopupMenuItem[], position: IPopupPosition): void {
    return this.getInstance().showContextMenu(menuItems, position);
  }

  public static showDescription(description: string, position: IPopupPosition): void {
    return this.getInstance().showDescription(description, position);
  }
}
