import { BaseService, createSingleton, UrlState, IUrl } from '@luxms/bi-core';


export interface ISearchVM {
  loading: boolean;
  error: string;
  search: string;
}

export class SearchVC extends BaseService<ISearchVM> {
  private readonly _urlState: UrlState;

  public static getInstance = createSingleton<SearchVC>(() => new SearchVC(), '__searchVC');

  protected constructor() {
    super({
      loading: false,
      error: null,
      search: null,
    });
    this._urlState = UrlState.getInstance();
    this._urlState.subscribeUpdatesAndNotify(this._updateUrl);
  }

  private _updateUrl = (model: IUrl) => {
    if (model?.loading || model?.error) return;
    this._updateModel({loading: false, search: null});
  }

  public setSearch(search: string) {
    // todo debounce
    this._updateModel({search});
  }

  protected _dispose() {
    super._dispose();
  }

}