export function getMonthSwitchTitle(url): string {
  let monthSwitchTitle: string = 'С начала месяца';

  // specific rzd2 rules
  if (url.segmentId === 'ds_16' && url.stat === '1' ||
      url.segmentId === 'ds_22' && url.rassl === '1' ||
      url.segmentId === 'ds_14' && url.dboard !== '2' ||
      url.segmentId === 'ds_22' && url.dboard === '4' ||
      url.segmentId === 'ds_23' || url.segmentId === 'ds_27' ||
      url.segmentId === 'ds_50' || url.segmentId === 'ds_21')
    monthSwitchTitle = 'Месяц';

  // if (url.segmentId === 'ds_22' && url.rassl == '1') monthSwitchTitle = '';                     // ОТСиТН, расследованные

  if (url.segmentId === 'ds_13' || url.segmentId === 'ds_47' ||
      url.segmentId === 'ds_14' && url.dboard === '4' ||
      url.segmentId === 'ds_22' && url.dboard === '4'
  )
    monthSwitchTitle = '';
  return monthSwitchTitle;
}
export function getQuarterSwitchTitle(url): string {
  let quarterSwitchTitle: string = 'С начала квартала';

  if (url.segmentId === 'ds_16' ||
      url.segmentId === 'ds_22' ||
      url.segmentId === 'ds_27' ||
      url.segmentId === 'ds_13' ||
      url.segmentId === 'ds_47' ||
      url.segmentId === 'ds_23' && url.dboard === '3' ||
      url.segmentId === 'ds_23' && url.dboard === '4' ||
      url.segmentId === 'ds_23' && url.dboard === '5' ||
      url.segmentId === 'ds_14' && url.dboard === '3' ||
      url.segmentId === 'ds_14' && url.dboard === '4' ||
      url.segmentId === 'ds_8' && url.dboard === '6' ||
      url.segmentId === 'ds_21' ||
      url.segmentId === 'ds_24' ||
      url.segmentId === 'ds_26' && url.dboard === '3' ||
      url.segmentId === 'ds_8' && url.dboard === '2' ||
      url.segmentId === 'ds_8' && url.dboard === '3' ||
      url.segmentId === 'ds_28' && url.dboard === '1' ||
      url.segmentId === 'ds_28' && url.dboard === '2' ||
      url.segmentId === 'ds_17' && url.dboard === '1' ||
      url.segmentId === 'ds_17' && url.dboard === '2')

    quarterSwitchTitle = '';          // no quarter

  // if (url.segmentId === 'ds_16' && url.stat !== '1') yearSwitchTitle = '';                      // Безопасность, оперативные
  // if (url.segmentId === 'ds_22' && url.rassl !== '1') yearSwitchTitle = '';                     // ОТСиТН, оперативные
  return quarterSwitchTitle;
}

export function getYearSwitchTitle(url): string {
  let yearSwitchTitle: string = 'С начала года';
  if (url.segmentId === 'ds_8' && url.dboard === '6' && (url.period.end === null || url.period.end > '202010') ||
      (url.segmentId === 'ds_23' && url.dboard === '3')
  ) {
    yearSwitchTitle = '';
  }
  return yearSwitchTitle;
}
export const checkTrends = (ds) => ds.schemaName === 'ds_44' || ds.schemaName === 'ds_45' || ds.schemaName === 'ds_46' || ds.schemaName === 'ds_50';

export const checkMainIndicators = (ds) => ds.schemaName === 'ds_7';
