import React from 'react';
import cn from 'classnames';
import clone from 'lodash/clone';
import {AppConfig, IDisposable, IUrl, UrlState} from '@luxms/bi-core';
import {ModalContainer, modalContainer} from './modal-container';
import {DrilldownMenu} from './dd-menu';
import {IShellVM} from '../view-controllers/ShellVC';
import {mouseWatcher} from '../libs/MouseWatcher';
import {data_engine} from '../data-manip/data-manip';
import {IDatasetModel, IVizelConfig, IVizelProps} from '../services/ds/types';
import {DatasetsListService, IDatasetsListItem, IDatasetsListModel} from '../services/DatasetsListService';
import $ from 'jquery';
// import skin from '../skins/skin.json';
import skin from './vars.scss';
import './Shell.scss';
import {ISubspace, ISubspacePtr, tables} from '../defs/bi';
import Vizel from './components/Vizel/Vizel';
import {IDsShellVM} from '../view-controllers/DsShellVC';
import LoadFromResources from './components/LoadFromResources';
import CustomStyleLoader from './CustomStyleLoader';

const DlgAuth = React.lazy(() => import('./components/AuthDlg/AuthDlg'));
const Popup = React.lazy(() => import('./dialogs/Popup'));

const DsShell = React.lazy(() => import('./DsShell/DsShell'));
const AdmShell = React.lazy(() => import('./adm'));
const RootShell = React.lazy(() => import('./Root/Root'));


interface ILayerProps {
  zIndex: number;
}

class Layer extends React.Component<ILayerProps> {
  public state: {
    dialog: any;
  } = {
    dialog: null,
  };
  private _wrapperContainer: React.RefObject<HTMLDivElement> = React.createRef();

  public setDialog(dlg: any) {
    this.setState({ dialog: dlg });
  }

  private _close = () => {
    this.setState({ dialog: null });
  }

  private _onClickWrapper = (event) => {
    if (event.target === this._wrapperContainer.current) {
      this.setState({ dialog: null });
    }
  }

  public render() {
    const {zIndex} = this.props;
    const {dialog} = this.state;

    if (!dialog) return null;

    return (
      <>
        <div className="ShellLayer__Shadow"
             style={{zIndex: zIndex}}
             onClick={this._close}></div>
        <div className="ShellLayer shell-layer-wrapper"
             ref={this._wrapperContainer}
             style={{zIndex: zIndex + 1}}
             onClick={this._onClickWrapper}>
          {dialog}
        </div>
      </>);
  }
}


interface IFlyout {
  vizel: IVizelProps;
  x: number;
  y: number;
}


export class Shell extends React.Component<IShellVM> {
  public state: {
    flyout: IFlyout | null;
    currentBookmark: any;
    SouthPanel: any;
    _datasetsListItems: IDatasetsListItem[];
    route: any;
  } = {
    flyout: null,
    currentBookmark: null,
    SouthPanel: null,
    _datasetsListItems: [],
    route: null
  };
  public modalContainer: ModalContainer = null;
  public layer50: Layer = null;

  public drilldownMenu: DrilldownMenu | null = null;
  private _mounted: boolean = false;
  private urlState: UrlState = null;

  public constructor(props: IShellVM) {
    super(props);
    shell = this;                                                                                   // HACK
    (window as any).shell = shell;                                                                  // NOT HACK, debug

    this.drilldownMenu = new DrilldownMenu();
    this.urlState = UrlState.getInstance();
    this.urlState.subscribeUpdatesAndNotify(this.urlChanged);
  }

  private _setupModalContainerRef = (ref: ModalContainer | null) => this.modalContainer = ref;
  private _setupLayer50Ref = (ref: Layer | null) => this.layer50 = ref;

  public componentDidUpdate(prevProps: Readonly<IShellVM>, prevState: Readonly<{}>, snapshot?: any): void {
    //
  }

  private _activeModule: React.RefObject<any> = React.createRef();


  private prevWidth = null;
  private prevHeight = null;

  private applyRem = () => {
    const $html = $('html');
    const width = $('.DsShell__Body').width() || $html.width();
    let height = $html.height();
    if ($('main.f_slideshow-pane-active').length) height -= 40;
    if (this.prevWidth !== width || this.prevHeight !== height) {
      this.prevWidth = width;
      this.prevHeight = height;
      const vmin = Math.min(width, 2 * height);   // aspect ratio: 2
      const remInPx = vmin / 100 * 0.75;
      const html = $html[0];
      html.style.fontSize = remInPx + 'px';
      html.style.setProperty('--scale', String(remInPx / 13));
    }
  };

  public componentDidMount(): void {
    this._mounted = true;
    this.applyRem();
    this.urlState.subscribeUpdatesAndNotify(this.urlChanged);
    window.onresize = () => {
      this.applyRem();
      const activeModule = this._activeModule.current;

      if (activeModule && activeModule.resize) {
        try {
          activeModule.resize();
        } catch (err) {
          console.log('activeItem = ', activeModule);
          console.error(err);
          console.log(err.stack);
        }
      }
      if (this.modalContainer) {
        this.modalContainer.resize();
      }
    };
  }

  public componentWillUnmount() {
    this._mounted = false;
    this.urlState.unsubscribe(this.urlChanged);
  }

  private urlChanged = (url) => {
    // только для скина bzh показываем дополнительную левую панель
    if (!(String(skin.hasDatasetsList) === 'true')) {
      if (url.route !== this.state.route) this.setState({route: url.route});
      return;
    }
    const segment = this.urlState.getModel().segment;

    if (segment === 'ds') {
      // TODO: сделать отдельный компонент, с подпиской на ресурсы
      DatasetsListService.subscribeUpdatesAndNotify((datasetsListModel: IDatasetsListModel) => {
        const loadThumbnailContent = (schema_name) =>
          fetch(AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/thumbnail.svg`), { credentials: 'same-origin' }).then(res => res.text());

        let datasets = (datasetsListModel.datasets || []).filter(d => !!d.schema_name && d.schema_name != 'ds_import_stat');
        Promise.all(datasets.map(d => d.schema_name).map(loadThumbnailContent)).then(thumbnailContents => {
          datasets = datasets.map((ds, i) => ({
            ...ds,
            thumbnailContent: thumbnailContents[i],
          }));

          this.setState({_datasetsListItems: datasets});
        });
      });
    } else {
      if (this._mounted) this.setState({_datasetsListItems: []});
      else this.state = {...this.state, _datasetsListItems: []};
    }
  }

  private _getModalVizels(): IVizelProps[] {
    if (!this.modalContainer) {
      return [];
    }
    return this.modalContainer.state.stack.map((ve) => ve.vizel).concat(this.modalContainer.state.vizel || []).filter(v => v != null);
  }

  public getCurrentContext(): any {
    const context: any = clone(UrlState.getModel());
    if (context.metrics == null || context.metrics.length == 0) {
      context.metrics = null;
    }

    const vzls: IVizelProps[] = this._getModalVizels();
    if (vzls.length) {
      context.modals = [];
      for (let vzl of vzls) {
        try {
          // ACHTUNG
          const { cfg, subspace} = vzl;

          const rawCfg: any = cfg.getRaw();
          if (subspace) {
            rawCfg.dataSource = clone(subspace.getRawConfig());
            const yAxisOrigin: string = rawCfg.dataSource.yAxis;        // 'metrics', 'locations', 'periods'

            const styleHash: any = {};
            for (let y of subspace.ys) {
              let styleHashEntry = {
                color: cfg.getColor(y) || y.color,
              };
              if (y.title != cfg.getTitle(y)) {
                styleHashEntry['title'] = cfg.getTitle(y);
              }
              styleHash[y.id] = styleHashEntry;
            }

            rawCfg.dataSource.style = {};
            rawCfg.dataSource.style[yAxisOrigin] = styleHash;
          }
          context.modals.push(rawCfg);
        } catch (err) {
          console.error(err);
        }
      }
    }

    return context;
  }

  public setFlyoutVizel(flyoutVizel: IVizelProps): void {
    if (flyoutVizel) {
      const mouseX = mouseWatcher.getMouseX();
      const mouseY = mouseWatcher.getMouseY();
      this.setState({ flyout: { vizel: flyoutVizel, x: mouseX, y: mouseY } });
    } else {
      this.setState({ flyout: null });
    }
  }

  public activateBookmark(slideModel: ISlideModel, isSilent: boolean = false, isReplaceUrl: boolean = false, additionalModel: IUrl = null): void {
    const ctx = slideModel.context;
    if (!ctx) {
      return;
    }

    const url: IUrl = {
      ...slideModel.context,
      ...additionalModel,
      segment: 'ds',
      segmentId: slideModel.context.segmentId || slideModel.context.dataset,
      slide: String(slideModel.id),
    };
    if (isSilent) {
      delete url.slide;
      url.displayMode = 'slide';
    }
    delete url.dataset;
    delete url.path;
    UrlState.getInstance().setModel(url, isReplaceUrl);

    const datasetId: string = ctx.segmentId || ctx.dataset;

    // modals
    // remove all modals
    if (this.modalContainer) {
      this.modalContainer.hide();
    }

    if (Array.isArray(ctx.modals) && ctx.modals.length) {
      // add modals
      let p: Promise<any> = Promise.resolve(null);
      let vizelItems: any[] = [];
      ctx.modals.forEach((modal) => {
        p = p.then((vizelItem: any) => {
          if (vizelItem) {
            vizelItems.push(vizelItem);
          }
          // TODO: populate datasetId to modal.dataSource if none is set
          return this._activateBookmarkModal(modal, datasetId);
        });
      });
      p.then((we: any) => {
        if (we) {
          vizelItems.push(we);
        }
        if (vizelItems.length) {
          const stack: any[] = vizelItems.slice(0, vizelItems.length - 1);
          const last: any = vizelItems[vizelItems.length - 1];
          modalContainer.setState({
            stack,
            vizel: last.vizel,
            title: last.title,
          });
        }
      });
    }
  }

  private async _activateBookmarkModal(modal: tables.IRawVizelConfig, defaultDatasetId: string): Promise<{vizel: IVizelProps, title: string}> {
    const service = await import('../services/service');
    const vizelConfig: IVizelConfig = await service.createVizelConfig(modal, defaultDatasetId);
    const dataset: IDatasetModel = vizelConfig.getDataset();
    const subspacePtr: ISubspacePtr = vizelConfig.getSubspacePtr();
    const dataProvider: data_engine.IDataProvider = dataset.getDataProvider();
    const { createSubspaceGenerator } = await import('../services/ds/createSubspaceGenerator');

    return new Promise<{ vizel: IVizelProps, title: string }>((resolve, reject) => {
      let subscription: IDisposable = createSubspaceGenerator(
        dataset.schema_name,
        subspacePtr,
        false,
        (subspace: ISubspace) => {
          subscription.dispose();
          subscription = null;
          resolve({
            vizel: {
              dp: dataProvider,
              cfg: vizelConfig,
              subspace,
            },
            title: vizelConfig.title || '',
          });
        });
    });
  }

  public setSouthPanel(SouthPanel: any) {
    this.setState({SouthPanel});
  }

  public render() {
    const { authenticated, popup, error, loading } = this.props;
    let { segment } = this.props;
    const { flyout, SouthPanel, _datasetsListItems, route } = this.state;
    const ds_res = _datasetsListItems.find(ds => ds.schema_name == 'ds_res');
    if (segment){
      segment.route = this.state.route ? this.state.route : segment.route;
    }
    let SegmentModule: any = null;

    // find out segment module
    if (!error && !loading && authenticated && segment) {
      if (segment.viewClassId === 'DsShell') SegmentModule = DsShell;
      else if (segment && segment.viewClassId === 'AdmShell') SegmentModule = AdmShell;
      else if (segment && segment.viewClassId === 'Root/Root') SegmentModule = RootShell;
    }
    return (
      <section className="Shell view shell">
        {!!error &&
          <div className="Shell__Error error-background">
            <img className="Shell__ErrorIcon" src="assets/icons/bug.svg" />
            <br/>
            <span>{error}</span>
          </div>}

        <CustomStyleLoader/>

        {!!_datasetsListItems.length &&
          <>
            <input className="ShellDsPanel__Toggler Shell__BagenDsToggler" type="checkbox"/>
            <div className="ShellDsPanel Shell__BagenDsList"
                 data-bind="iscroll:{mouseWheel: true, scrollbars: false, interactiveScrollbars: true, scrollX: false, scrollY: true, click: true, tap: true},css:{notAuth: authenticated == false}">
              <ul className="ShellDsPanel__List">
                {ds_res &&
                  <li className={cn('ShellDsPanel__Item ShellDsPanel__ItemLogo', {})}
                      key={'ds_logo'}
                      data-schema-name={ds_res.schema_name}>
                    <a className="ShellDsPanel__DsLink">
                      <div className="ShellDsPanel__DsThumbnail" dangerouslySetInnerHTML={{__html: (ds_res as any).thumbnailContent}} />
                      <span className="ShellDsPanel__DsTitle"></span>
                    </a>
                  </li>}

                {_datasetsListItems.filter(ds => ds.schema_name != 'ds_res').map(ds =>
                  <li className={cn('ShellDsPanel__Item', {active: (segment as IDsShellVM)?.schemaName === ds.schema_name, empty: (ds as any).thumbnailContent == ''})}
                      title={ds.title}
                      key={ds.schema_name}
                      data-schema-name={ds.schema_name}>
                    <a className="ShellDsPanel__DsLink" href={ds.href}>
                      <div className="ShellDsPanel__DsThumbnail" dangerouslySetInnerHTML={{__html: (ds as any).thumbnailContent}} />
                      <span className="ShellDsPanel__DsTitle">{ds.title}</span>
                    </a>
                  </li>)}
              </ul>
            </div>
          </>}


        {/* ☰ menu */}

        {!error && !loading && !authenticated &&
          <React.Suspense fallback={null}>
            <LoadFromResources path="DlgAuth.js">
              <DlgAuth />
            </LoadFromResources>
          </React.Suspense>}

        {!!popup &&
          <React.Suspense fallback={null}>
            <Popup {...popup}/>
          </React.Suspense>}

        {/* modal container */}
        <ModalContainer ref={this._setupModalContainerRef}/>

        <Layer zIndex={50} ref={this._setupLayer50Ref}/>

        {/* flyout vizel */}
        {!!flyout &&
          <div className="FlyoutVizel flyout-vizel popover fade bottom in" role="tooltip"
               style={{left: flyout.x, top: flyout.y}}>
            <div className="arrow" style={{zIndex: 2}}/>
            <div className="popover-content contextMenu">
              <Vizel {...flyout.vizel}/>
            </div>
          </div>}

        <main id="content"
              className={SouthPanel ? 'f_slideshow-pane-active' : ''} >

          {!!loading &&
            <img className="main-loading-image" src="assets/logo/logo-animated.svg"/>}

          {!!SegmentModule &&
            <>
              <React.Suspense fallback={null}>
                <SegmentModule ref={this._activeModule} {...segment}/>
              </React.Suspense>

              {SouthPanel}
            </>}

        </main>
        <footer></footer>
      </section>);
  }
}


// HACK
export let shell: Shell = null;
export function getShell(): Shell {
  return shell;
}

export default shell;
