/**
 *  Дерево Фенвика
 */


class FenwickTree {
  private readonly _items: number[];
  private _defaultSize: number;

  public constructor(length: number, defaultSize: number) {
    this._items = new Array(length);
    this._defaultSize = Math.max(defaultSize, 1);
  }

  public setDefaultSize(defaultSize: number): void {
    this._defaultSize = Math.max(1, defaultSize);
  }

  public sum(i: number): number {
    let result = 0;
    for (i = Math.max(i, 0) - 1; i >= 0; i = (i & (i + 1)) - 1) {
      if (this._items[i] === undefined) {                                                           // fallback
        let k = (i & (i + 1)) - 1;
        result += (i - k) * this._defaultSize;
      } else {
        result += this._items[i];
      }
    }
    return result;
  }

  private _inc(i: number, delta) {
    for (; i < this._items.length; i = (i | (i + 1))) {
      if (this._items[i] === undefined) {
        let k = (i | (i + 1));
        this._items[i] = (k - i) * this._defaultSize + delta;
      } else {
        this._items[i] += delta;
      }
    }
  }

  // меняет размер i-го элемента
  public set(i: number, size: number) {
    let currentSize = this.sum(i + 1) - this.sum(i);
    this._inc(i, -currentSize + size);
  }

  // Находит индекс элемента по указанной позиции
  public idx(pos: number): number {
    let i = 0, k = 1;
    while (this.sum(k) <= pos) {
      i = k;
      k *= 2;
    }
    while (k - i > 1) {
      let m = (i + k) >> 1;
      if (this.sum(m) <= pos) i = m;
      else k = m;
    }
    return i;
  }

  // общий размер
  public totalSize(): number {
    const len = this._items.length;
    return this.sum(len);
  }

  public getLength(): number {
    return this._items.length;
  }
}

export default FenwickTree;
