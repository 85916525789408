import { AuthenticationService, BaseService, createSingleton, IDisposable, UrlState } from '@luxms/bi-core';

export interface IEditModeModel {
  error: string;
  loading: boolean;
  enabled: boolean;                                                                                 // Возможен ли режим редактирования для текущего пользователя
  editMode: boolean;
}

class EditModeVC extends BaseService<IEditModeModel> {
  public static MODEL: IEditModeModel;

  protected constructor() {
    super({
      error: null,
      loading: true,
      enabled: false,
      editMode: false,
    });

    AuthenticationService.subscribeUpdates(this._onSrvUpdated);
    UrlState.subscribeUpdates(this._onSrvUpdated);
    // TODO: добавить подписку/переподписку на права dataset

    this._onSrvUpdated();
  }

  private _onSrvUpdated = () => {
    const auth = AuthenticationService.getModel();
    const url = UrlState.getModel();
    if (auth.error || url.error) return this._updateWithError(auth.error || url.error);
    if (auth.loading || url.loading) return this._updateWithLoading();

    // TODO: добавить проверки на права к датасету
    // стартовая страница условие
    const enabled = auth.access_level === 'admin' && (url.route === '#dashboards' || (url.segment === 'ds' && url.segmentId === null) || (url.segment === 'ds' && /@_/.test(url.segmentId)));

    this._updateWithData({enabled, editMode: enabled ? this._model.editMode : false});
  }

  public enterEditMode() {
    if (this._model.error) throw new Error(this._model.error);
    if (this._model.loading) throw new Error('loading');
    if (!this._model.enabled) throw new Error('Edit mode disabled');
    this._updateModel({editMode: true});
  }

  public exitEditMode() {
    this._updateModel({editMode: false});
  }

  public save() {
    this._notify('save');
    this.exitEditMode();
  }

  public reset() {
    this._notify('reset');
    this.exitEditMode();
  }

  public static enterEditMode() {
    return this.getInstance().enterEditMode();
  }

  public static exitEditMode() {
    return this.getInstance().exitEditMode();
  }

  public static save() {
    return this.getInstance().save();
  }

  public static reset() {
    return this.getInstance().reset();
  }

  public static getModel(): IEditModeModel {
    return this.getInstance().getModel();
  }

  public static subscribeUpdatesAndNotify(listener: (model: IEditModeModel) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static subscribe(event: string, listener: any): IDisposable {
    return this.getInstance().subscribe(event, listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }

  public static getInstance = createSingleton(() => new EditModeVC(), '__editModeVC');
}

export default EditModeVC;
