exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/*!\n    common variables/functions/settings\n    can be overrided via skin.json\n */.DsShellHeader{height:3.5rem;white-space:nowrap;text-overflow:ellipsis;word-break:break-all;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:flex-start}.DsShellHeader>*{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-break:break-all}.DsShellHeader .DsShellHeader__Toolbar{flex:2 100%;flex-grow:1;position:relative}.DsShellHeader__Logo{flex-grow:0;flex-shrink:0;vertical-align:middle;text-overflow:clip}.DsShellHeader__LogoLink{display:flex;align-items:center;justify-content:center;padding:0 10px;height:100%;color:#fff;text-decoration:none}.DsShellHeader__LogoLink:hover{text-decoration:none}.MainToolbar .bi-icon{width:2rem;height:2rem;border-radius:.5rem;margin-right:1rem}.MainToolbar .bi-icon svg{height:62%;width:62%;fill:var(--color6);stroke:none}.MainToolbar .bi-icon:hover,.MainToolbar .bi-icon.active{box-shadow:var(--shadow_button)}", ""]);

// exports
exports.locals = {
	"hasDatasetsList": "false",
	"hasFullMainToolbar": "false",
	"mainFontFamily": "\"Golos UI\"",
	"mainFontSize": "16px",
	"mainColor": "var(--color6)",
	"font": "16px \"Golos UI\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif"
};