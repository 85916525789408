import { AppConfig, BaseService, extractErrorMessage } from '@luxms/bi-core';

import axios, { CancelToken, CancelTokenSource } from 'axios';
import { IKoobDimension, IKoobMeasure } from '../../defs/bi';
import AlertsVC from '../../view-controllers/AlertsVC';


interface IKoobDataRequest3 {
  options?: string[];
  offset?: number;
  limit?: number;
  sort?: string[];
  subtotals?: string[];
  cancelToken?: CancelToken;
}

export async function koobCountRequest3(koobId: string,
                                        dimensions: string[],
                                        measures: string[],
                                        allFilters: any,
                                        request: IKoobDataRequest3 = {},
                                        comment?: string) {
  const url: string = AppConfig.fixRequestUrl(`/api/v3/koob/count` + (comment ? '?' + comment : ''));
  const columns = dimensions.concat(measures);

  let filters: any;
  if (allFilters && typeof allFilters === 'object') {
    filters = {};
    Object.keys(allFilters).forEach(key => {
      let value = allFilters[key];
      if (value === '∀' || value === '*') {                                                         // фильтр подразумевающий 'ВСЕ'
        return;                                                                                     // просто не выносим в фильтры
      } else if (Array.isArray(value) && value[0] === 'IN') {                                       // много где сконфигурировано ['IN', 'a', 'b']
        value = ['='].concat(value.slice(1));
      }
      filters[key] = value;
    });
  }

  const body: any = {
    with: koobId,
    columns,
    filters,
  };

  if (request.offset) body.offset = request.offset;
  if (request.limit) body.limit = request.limit;
  if (request.sort) body.sort = request.sort;
  if (request.options) body.options = request.options;
  if (request.subtotals?.length) body.subtotals = request.subtotals;

  if (!measures.length) {                                                                           // если нет measures, то лучше применить distinct
    body.distinct = [];
  }

  /*  const response = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/stream+json',
      },
      data: body,
      cancelToken: request.cancelToken,
    });

    let data = response.data;

    if (String(response.headers['content-type']).startsWith('application/stream+json')) {
      if (typeof data === 'string') {
        data = data.split('\n').filter((line: string) => !!line).map((line: string) => JSON.parse(line));
      } else if (data && (typeof data === 'object') && !Array.isArray(data)) {
        data = [data];
      }
    }

    return data;*/

  try {
    const response = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/stream+json',
      },
      data: body,
      cancelToken: request.cancelToken,
    });

    let data = response.data;

    if (String(response.headers['content-type']).startsWith('application/stream+json')) {
      if (typeof data === 'string') {
        data = data.split('\n').filter((line: string) => !!line).map((line: string) => JSON.parse(line));
      } else if (data && (typeof data === 'object') && !Array.isArray(data)) {
        data = [data];
      }
    }

    return data;
  } catch (e) {
    AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(e));
    return '';
  }

}


export async function koobDataRequest3(koobId: string,
                                       dimensions: string[],
                                       measures: string[],
                                       allFilters: any,
                                       request: IKoobDataRequest3 = {},
                                       comment?: string) {
  const url: string = AppConfig.fixRequestUrl(`/api/v3/koob/data` + (comment ? '?' + comment : ''));
  const columns = dimensions.concat(measures);

  let filters: any;
  if (allFilters && typeof allFilters === 'object') {
    filters = {};
    Object.keys(allFilters).forEach(key => {
      let value = allFilters[key];
      if (value === '∀' || value === '*') {                                                         // фильтр подразумевающий 'ВСЕ'
        return;                                                                                     // просто не выносим в фильтры
      } else if (Array.isArray(value) && value[0] === 'IN') {                                       // много где сконфигурировано ['IN', 'a', 'b']
        value = ['='].concat(value.slice(1));
      }
      filters[key] = value;
    });
  }

  const body: any = {
    with: koobId,
    columns,
    filters,
  };

  if (request.offset) body.offset = request.offset;
  if (request.limit) body.limit = request.limit;
  if (request.sort) body.sort = request.sort;
  if (request.options) body.options = request.options;
  if (request.subtotals?.length) body.subtotals = request.subtotals;

  if (!measures.length) {                                                                           // если нет measures, то лучше применить distinct
    body.distinct = [];
  }

  // test
  // body.limit = 2;

  try {
    const response = await axios({
      url,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/stream+json',
      },
      data: body,
      cancelToken: request.cancelToken,
    });

    let data = response.data;

    if (String(response.headers['content-type']).startsWith('application/stream+json')) {
      if (typeof data === 'string') {
        data = data.split('\n').filter((line: string) => !!line).map((line: string) => JSON.parse(line));
      } else if (data && (typeof data === 'object') && !Array.isArray(data)) {
        data = [data];
      }
    }

    return data;

  } catch (e) {
    AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(e));
    return '';
  }

}


export interface IKoobDataModel {
  loading?: boolean;
  error?: string;
  dimensions: IKoobDimension[];
  measures: IKoobMeasure[];
  values: any[];
  sort?: string[];
  subtotals?: string[];
}

export class KoobDataService extends BaseService<IKoobDataModel> {
  public static koobDataRequest3 = koobDataRequest3;
  public static koobCountRequest3 = koobCountRequest3;
  private readonly _koobId: string;
  private _dimensions: IKoobDimension[];
  private _measures: IKoobMeasure[];
  private _filters: any = undefined;
  private _loadBy: number | null = null;
  private _totalPages: number | null = null;
  private _loadedPages: number | null = null;
  private _sort: string[] | null = null;
  private _subtotals: string[] | null = null;

  public constructor(koobId: string,
                     dimensions: IKoobDimension[],
                     measures: IKoobMeasure[],
                     filters: any,
                     loadBy?: number,
                     sort?: any,
                     subtotals?: string[]) {
    super({
      loading: true,
      error: null,
      dimensions: [],
      measures: [],
      values: [],
      sort: null,
      subtotals: null,
    });
    this._sort = typeof sort === 'string' ? [sort] : sort;
    this._koobId = koobId;
    this._dimensions = dimensions;
    this._measures = measures;
    this._filters = filters;
    this._loadBy = loadBy ?? null;
    this._subtotals = Array.isArray(subtotals) ? subtotals : null;
    this._load();
  }

  protected _dispose() {
    this.abort();
    super._dispose();
  }

  public abort() {
    if (this._loadCancel) {
      this._loadCancel.cancel('canceled');
      this._loadCancel = null;
    }
  }

  public reload() {
    this.abort();
    this._loadedPages = null;
    this._load();
  }

  private async loadPage(page: number) {
    if (this._totalPages !== null) return;                                                          // Все загрузилось
    if (page < this._loadedPages) return;                                                           // уже грузятся

    let loadedPages = this._loadedPages;
    this._loadedPages = page + 1;                                                                   // выставим переменную, чтоб знали, что уже грузится

    this._updateModel({loading: true});

    for (let p = loadedPages; p <= page; p++) {
      const newValues = await koobDataRequest3(
          this._koobId,
          this._dimensions.map(d => d.id),
          this._measures.map(m => m.formula),
          this._filters,
          {offset: page * this._loadBy, limit: this._loadBy, sort: this._sort, subtotals: this._subtotals});
      // console.log('Loaded new values with offset=', page * this._loadBy, 'limit=', this._loadBy, 'loaded=', newValues.length);
      // сохраняем в данные
      let values = this._model.values.slice(0);
      for (let i = 0; i < newValues.length; i++) {
        values[page * this._loadBy + i] = newValues[i];
      }
      this._updateModel({values});
      if (newValues.length < this._loadBy) {                                                        // загрузилось не все - значит, кончилось
        this._loadedPages = this._totalPages = p + 1;
        break;
      }
    }
    this._updateModel({loading: false});
  }

  public loadItem(n: number) {
    if (!this._loadBy) return;                                                                      // данные грузятся полностью
    if (this._totalPages !== null) return;                                                          // есть переменная выставленная - значит все загружено (или грузится)
    if (n < this._model.values.length) return;                                                      // загружено или грузится

    let page = Math.ceil(n / this._loadBy);
    this.loadPage(page);
  }

  public setSort(sort: string | string[] | null) {
    this._sort = typeof sort === 'string' ? [sort] : sort;
    this._totalPages = null;
    this._loadedPages = null;
    this._load();
  }

  private _loadCancel: CancelTokenSource | null = null;

  private async _load() {
    try {
      this._updateWithLoading();

      this._loadCancel = axios.CancelToken.source();

      const values = await koobDataRequest3(
          this._koobId,
          this._dimensions.map(d => d?.formula || d.id), // для сгенерированных dimension
          this._measures.map(m => m.formula),
          this._filters,
          {offset: 0, limit: this._loadBy, sort: this._sort, cancelToken: this._loadCancel.token, });

      this._loadCancel = null;

      if (this._loadBy) {
        this._loadedPages = 1;
        // console.log('Loadede page 0: total=', values.length);
        if (values.length < this._loadBy) {
          this._totalPages = 1;
        }
      }

      this._updateWithData({
        dimensions: this._dimensions,
        measures: this._measures,
        values,
        sort: this._sort,
      });
    } catch (err) {
      this._updateWithError(extractErrorMessage(err));
    }
  }

  public setFilter(filters: any) {
    this._filters = filters;
    this._totalPages = null;
    this._loadedPages = null;
    this._load();
  }
}
