/**
 *
 *
 *
 *
 */

import { BaseService, memLock, AppConfig, AuthenticationService, IAuthentication } from '@luxms/bi-core';
import { DatasetModel } from './DatasetModel';
import axios from 'axios';
import { IDatasetModel, IDatasetServiceModel } from './types';
import { lang } from '../../utils/utils';
import { responses } from '../../defs/bi';


const MIN_UPDATE_SEC = 60;


export class DatasetService extends BaseService<IDatasetServiceModel> {
  private readonly _datasetId: string | number;

  public constructor(datasetId: string | number) {
    super({
      loading: true,
      error: null,
      dataset: null,
    });
    this._datasetId = datasetId;
    this._subscriptions.push(AuthenticationService.subscribeUpdatesAndNotify(this._reload));
  }

  @memLock
  private _reload = async () => {
    const auth: IAuthentication = AuthenticationService.getModel();
    if (auth.error) {
      window.clearTimeout(this._updateTimerId);
      this._updateTimerId = null;
      this._updateWithError(auth.error);
      return;
    }
    if (auth.loading) {
      window.clearTimeout(this._updateTimerId);
      this._updateTimerId = null;
      this._updateWithData({ dataset: this._model.dataset });
      return;
    }
    if (!auth.authenticated) {
      window.clearTimeout(this._updateTimerId);
      this._updateTimerId = null;
      this._updateWithError(AuthenticationService.NOT_AUTHENTICATED);
      return;
    }

    try {
      this._updateModel({loading: true});
      const url = AppConfig.fixRequestUrl(`/api/datasets/${this._datasetId}`);
      const result: responses.IDatasetResponse = (await axios.get(url)).data;
      const dataset: IDatasetModel = new DatasetModel(result.dataset, result.tables);

      // datasetService must be available by id, guid and schemaName
      DatasetService._cache[dataset.id] = this;
      DatasetService._cache[dataset.guid] = this;
      DatasetService._cache[dataset.schema_name] = this;

      this._planUpdate(dataset.getConfigHelper().getStringValue('dataset.updateEvery'));

      // DEBUG
      (window as any).updateDataset = () => this._updateDataset();

      this._updateModel({
        error: null,
        loading: false,
        dataset,
      });

    } catch (err) {
      console.error('Failed to load dataset model', err.message);
      console.error(err);

      let errorCode: string = 'wrong_data_format';

      try {
        if (err.isAxiosError) {
          const response = err.response;
          const {key, type, message} = response.data;
          if (key === 'DATASET_ACCESS_DENIED') {
            errorCode = 'DATASET_ACCESS_DENIED';
          }
        }
      } catch (err) {
        // ...
      }

      this._updateWithError(lang(errorCode));
    }
  };

  private async _updateDataset(): Promise<void> {
    try {
      const {loading, error, dataset} = this._model;
      if (error) {
        return;
      }
      if (loading) {
        return;
      }

      const url = AppConfig.fixRequestUrl(`/api/datasets/${this._datasetId}`);
      const result: responses.IDatasetResponse = (await axios.get(url)).data;
      dataset.update(result.dataset, result.tables);
      this._planUpdate(result.dataset.ui_cfg['dataset.updateEvery']);

    } catch (err) {
      // error occured during update... No network?
      this._planUpdate('30s');
    }
  }

  private _updateTimerId: number = null;

  private _planUpdate(updateEvery: string) {
    const {loading, error, dataset} = this._model;

    window.clearTimeout(this._updateTimerId);

    if (!updateEvery) {
      return;
    }

    if (!updateEvery.match(/^(\d+)(.+)$/)) {
      console.warn('cannot parse: updateEvery=%s', updateEvery);
      return;
    }
    const duration = moment.duration(parseInt(RegExp.$1), RegExp.$2);
    const serial = dataset.getSerial();
    const nextUpdate = serial.clone().add(duration);

    console.log(`Dataset '${this._datasetId}': ${serial.format()} + ${updateEvery} = ${nextUpdate.format()}`);

    const now = moment();
    let diff = nextUpdate.diff(now, 'seconds');

    if (diff < MIN_UPDATE_SEC) {
      console.warn(`Dataset update ${this._datasetId} Next is scheduled less then ${MIN_UPDATE_SEC} sec to future (${diff})`);
      diff = MIN_UPDATE_SEC;
    }

    console.log(`Dataset '${this._datasetId}' will update in ${diff} seconds`);
    this._updateTimerId = window.setTimeout(async () => {
      try {
        await this._updateDataset();
      } catch (err) {
        console.error(`Error updating dataset ${this._datasetId}`, err);
        console.log(err.stack);
      }
    }, diff * 1000);
  }


  protected _dispose() {
    window.clearTimeout(this._updateTimerId);
    this._updateTimerId = null;
    delete DatasetService._cache[this._datasetId];
    const dataset: IDatasetModel = this._model.dataset;
    if (dataset) {
      delete DatasetService._cache[dataset.id];
      delete DatasetService._cache[dataset.guid];
      delete DatasetService._cache[dataset.schema_name];
    }
    super._dispose();
  }

  public static createInstance(id: string | number): DatasetService {
    if (id in DatasetService._cache) {
      return DatasetService._cache[id].retain();
    }

    for (let key in DatasetService._cache) {
      if (DatasetService._cache.hasOwnProperty(key)) {
        const ds: IDatasetModel = DatasetService._cache[key].getModel().dataset;
        if (ds && (ds.id === id || ds.guid === id || ds.schema_name === id)) {
          return DatasetService._cache[key].retain();
        }
      }
    }

    const obj: DatasetService = new DatasetService(id);        // counter = 1, no retain needed
    DatasetService._cache[id] = obj;
    return obj;
  }

  // TODO: createObjectCache
  private static _cache: {[id: string]: DatasetService} = {};

}

const _cache: {[id: string]: DatasetService} = {};

// DEBUG
if (typeof window !== 'undefined') {
  (window as any).__datasetServices = _cache;
}
