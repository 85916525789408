import { BaseService, createSingleton } from '@luxms/bi-core';
import React from 'react';

export interface IOpenModalVMOpt {
  cancelWrapper?: boolean;   // default = true,  закрытие модального окна по клику на wrapper
  hiddenWrapper?: boolean;   // default = false,  наличие wrapper'a
  style?: { [id: string]: string | number };
  className?: string;
  // ... todo дальнейшее расширение
}

export interface IOpenModalVM {
  loading: boolean;
  error: string;
  reactEl: React.ReactElement;
  args: any;
  options: any;
}

export async function openModal(el: React.ReactElement, options?: IOpenModalVMOpt): Promise<any> {
  const service: OpenModalVC = OpenModalVC.getInstance();
  service.setVizel(el, options);
  await service.whenReady();
  return service.getModel().args;
}

export class OpenModalVC extends BaseService<IOpenModalVM> {

  public static getInstance = createSingleton<OpenModalVC>(() => new OpenModalVC(), '__openModalVC');

  private constructor() {
    super({
      loading: false,
      error: null,
      reactEl: null,
      args: null,
      options: null,
    });
  }

  protected _dispose() {
    super._dispose();
  }

  public setVizel = (reactEl: React.ReactElement, options: IOpenModalVMOpt = null): void => {
    this._updateModel({reactEl, loading: true, error: null, options: options, args: null});
  }

  // чтобы в catch перехватить 'ошибку'
  public onModalCancel = (args: any): void => {
    this._updateModel({reactEl: null, args: null, loading: false, error: args ?? 'Cancelled'});
  }

  public onModalResult = (args: any): void => {
    this._updateModel({reactEl: null, args: args, loading: false, error: null});
  }

}