import React, {useState} from 'react';
import { IMainToolbarVM, MainToolbarVC } from '../../view-controllers/panels/MainToolbarVC';
import {MainToolbar} from '../panels/MainToolbar/MainToolbar';
import {lang} from '../../utils/utils';
import {AppConfig, AuthenticationService} from '@luxms/bi-core';
import {DsShellHeaderLogo} from './DsShellHeaderLogo';
import {Header} from '@luxms/bi-face';
import AccountTool from '@luxms/bi-face/AccountTool';
import ActionList from '../../../srx/components/action/ActionList';
import {BIIcon} from '../components/BIIcon/BIIcon';
import './DsShellHeader.scss';
import useService, { useServiceItself } from '../useService';
import {BreadcrumbControl} from '../Root/BreadcrumbControl';
const skin: any = require('../../skins/skin.json');


interface IDsShellHeaderProps {
  schema_name: string;
  dsTitle: string;
  dsDescription: string;
  dsUrl: string;
}

export const DsShellHeader = React.memo(({schema_name, dsTitle, dsDescription, dsUrl}: IDsShellHeaderProps) => {
  const authenticationService = useServiceItself<AuthenticationService>(AuthenticationService);
  const [accountMenuVisible, setAccountMenuVisible] = useState(false);
  const mainToolbar = useService<MainToolbarVC>(MainToolbarVC, schema_name);

  const onPressSignOut = async () => {
    await authenticationService.signOut();
    window.location.hash = '#';
  };

  const handleHide = () => setAccountMenuVisible(false);
  const toggleShow = () => setAccountMenuVisible(!accountMenuVisible);
  const handleClickAction = (key: string) => {
    if (key === 'logout') onPressSignOut();
    handleHide();
  };
  const toggleDatasetDescription = () => {
    // TODO
  };

  const title = skin.datasetLogoTitle || authenticationService.getModel()?.username ;

  const accountMenu = (
    <ActionList onClick={(key) => handleClickAction(key)} className="AccountList">
      {/*<ActionList.Action key="profile" className="AccountList__Item">{lang('profile')}</ActionList.Action>*/}
      <ActionList.Action key="logout" className="AccountList__Item">{lang('log_out')}</ActionList.Action>
    </ActionList>);

  return (
   <></>
  )
});
