import React from 'react';
import './DatasetCard.scss';
import cn from 'classnames';

import {srv, repo, AuthenticationService, AppConfig} from '@luxms/bi-core';
import DatasetsService = srv.adm.DatasetsService;
import IRawDataset = repo.adm.IRawDataset;

import {DatasetImage} from '../../../../components/DatasetImage/DatasetImage';
import ActionList from '../../../../components/action/ActionList';
import {lang} from '../../../../../src/utils/utils';
import {Dropdown} from '@luxms/bi-face';
import DatasetSnapshotManager from '../../DatasetSnapshotManager';
import {BIIcon} from '../../../../../src/views/components/BIIcon/BIIcon';
import {UsersDatasetsMapService} from '../../../../services/UserDatasetsMapService';


interface IDatasetCardProps {
  schema_name: string;
  onClickAction?: (key: string, dataset: any) => void;
  draggable: boolean;
  edit: boolean;
}

interface IDatasetCardState {
  error: string;
  loading: boolean;
  dataset: IRawDataset;
  //
  overlayActive: boolean;
  visibleDropDown: boolean;
  rules: string[];
}

export class DatasetCard extends React.Component<IDatasetCardProps, IDatasetCardState> {
  private readonly _datasetsService: DatasetsService;
  private readonly _appConfig: AppConfig;
  private readonly _usersDatasetsMapService: UsersDatasetsMapService;

  public state: IDatasetCardState;

  public constructor(props: IDatasetCardProps) {
    super(props);

    this.state = {loading: true, error: null, dataset: null, overlayActive: false, visibleDropDown: false, rules: []};

    this._usersDatasetsMapService = UsersDatasetsMapService.getInstance();
    this._datasetsService = DatasetsService.getInstance();
    this._appConfig = AppConfig.getInstance();
  }

  public componentDidMount() {
    this._datasetsService.subscribeUpdatesAndNotify(this._onUpdateModel);
    this._usersDatasetsMapService.subscribeUpdatesAndNotify(this._onUpdateUserMap);
  }

  public componentWillUnmount() {
    this._datasetsService.unsubscribe(this._onUpdateModel);
    this._usersDatasetsMapService.unsubscribe(this._onUpdateUserMap);
  }

  // update model
  private _onUpdateModel = (model): void => {
    if (model.loading || model.error) {
      this.setState({loading: model.loading, error: model.error});
      return;
    }
    // получаю датасет
    const {schema_name} = this.props;
    const dataset = model.find(ds => ds.schema_name === schema_name);
    this.setState({dataset, loading: false, error: null});
  }
  private _onUpdateUserMap = async (model): Promise<void> => {
    if (model.loading || model.error) return;
    const {schema_name} = this.props;
    await this._datasetsService.whenReady();
    const authenticationSrv = AuthenticationService.getInstance().getModel();

    const dataset_id = this._datasetsService.getModel().find(ds => ds.schema_name === schema_name).id;
    const user_id = authenticationSrv.userId;
    const rule = model.filter((m) => m.user_id === user_id && m.dataset_id === dataset_id).map(m => m.op);
    const rules = authenticationSrv.access_level === 'admin' ? ['delete', 'update', 'create', 'read'] : rule;
    this.setState({rules});
  }

  // click btn
  private _onClickBtn = (e): void => {
    e.stopPropagation();
    this.setState({overlayActive: !this.state.overlayActive});
  }
  private _onClickAction = (key: string): void => {
    if (!this.props.onClickAction) return;
    const {dataset} = this.state;
    this.props.onClickAction(key, dataset);
  }

  public render() {
    const {draggable, schema_name, edit} = this.props;
    const {dataset, loading, error, overlayActive, visibleDropDown, rules} = this.state;

    if (!dataset) return <article className="DatasetCard error">Cannot find dataset {schema_name}</article>;
    if (error) return <article className="DatasetCard error">{error}</article>;
    if (loading) return <article className="DatasetCard loading"><DatasetCardLoadingContents/></article>;

    const hideMLPFields = this._appConfig?.getModel()?.features?.includes('DisableMLP') ?? false;
    // todo hack чтобы убрать из главной страницы
    const hasAdmin = (document.location.href).match('admin');

    return (
      <article className={cn('DatasetCard', edit ? 'edit' : '')} draggable={draggable} onClick={() => this._onClickAction('edit')}>

        <div className="DatasetCard__Inner">
          <div className="DatasetCard__Image"><DatasetImage schema_name={dataset.schema_name} edit={edit}/></div>

          <div className="DatasetCard__Content">
            <h1 className="DatasetCard__Title">{dataset.title}</h1>
            <h2 className="DatasetCard__Description" title={dataset.description}>{dataset.description}</h2>
          </div>

          <div className={cn('DatasetCard__Overlay', {active: overlayActive && edit})} onClick={e => e.stopPropagation()}>
            <h1 className="DatasetCard__Title tiny">{dataset.title}</h1>

            <div className="DatasetCard__ListContainer">
              <ActionList className="DatasetCard__ActionList" onClick={(key) => this._onClickAction(key)}>

                {
                  rules.includes('update') &&
                  <ActionList.Action key="edit" className="DatasetCard__Action">{lang('edit')}</ActionList.Action>
                }

                {
                  rules.includes('read') && hasAdmin &&
                  <ActionList.Action key="users" className="DatasetCard__Action">{lang('users')}</ActionList.Action>
                }

                {
                  rules.includes('read') && hasAdmin &&
                  <ActionList.Action key="groups" className="DatasetCard__Action">{lang('groups')}</ActionList.Action>
                }
                {!hideMLPFields && rules.includes('update') && hasAdmin &&
                <ActionList.Action key="importIncrement"
                                   className="DatasetCard__Action">{lang('import')}</ActionList.Action>
                }
                {!hideMLPFields && rules.includes('update') && hasAdmin &&
                <ActionList.Action key="importOperations"
                                   className="DatasetCard__Action">{lang('operations')}</ActionList.Action>
                }
                {!hideMLPFields && rules.includes('update') &&
                <ActionList.Action key="clean" className="DatasetCard__Action" confirm={lang('confirm_clean')}>
                  {lang('clean')}
                </ActionList.Action>
                }
                {rules.includes('delete') &&
                <ActionList.Action key="delete" confirm={lang('confirm_delete')} className="DatasetCard__Action"
                                   confirmClassName="DatasetCard__Confirm">
                  {lang('delete')}
                </ActionList.Action>
                }
              </ActionList>

              <ul className="DatasetCard__ActionList">
                <Dropdown.Trigger
                  trigger="click"
                  placement="right-start"
                  visible={this.state.visibleDropDown}
                  onClickOutside={() => this.setState({visibleDropDown: false})}
                  onClick={() => this.setState({visibleDropDown: !visibleDropDown})}
                  menu={<DatasetSnapshotManager visible={visibleDropDown} schemaName={schema_name}
                                                onClose={() => this.setState({visibleDropDown: false})}/>}>
                  <li className="DatasetCard__Action">{lang('snapshotMenu')}</li>
                </Dropdown.Trigger>
              </ul>

            </div>
          </div>
        </div>

        <div className="DatasetCard__MetaContainer" onClick={e => e.stopPropagation()}>
          {edit && <DatasetStatusBadge schema_name={dataset.schema_name}/>}
          {edit && <BIIcon icon="menu" className="DatasetCard__Button" onPress={this._onClickBtn}/>}
        </div>
      </article>
    );
  }
}


class DatasetStatusBadge extends React.Component<{ schema_name: string }> {
  private _datasetService: DatasetsService;

  public state: {
    id: number,
    loading: boolean,
    error: string,
    is_visible: boolean,
    editSchemaName: boolean,
    schemaNameValue: string,
    schemaError: string,
  };

  constructor(props: { schema_name: string }) {
    super(props);
    this._datasetService = DatasetsService.getInstance();

    this.state = {
      id: null,
      loading: true,
      error: null,
      is_visible: false,
      editSchemaName: false,
      schemaNameValue: '',
      schemaError: '',
    };
  }

  public componentDidMount() {
    this._datasetService.subscribeUpdatesAndNotify(this._onSvcUpdated);
    this.setState({schemaNameValue: this.props.schema_name});
  }

  public componentDidUpdate(prevProps: Readonly<{ schema_name: string }>) {
    if (prevProps.schema_name !== this.props.schema_name) this.setState({schemaNameValue: this.props.schema_name});
  }

  public componentWillUnmount() {
    this._datasetService.unsubscribe(this._onSvcUpdated);
  }

  private _onSvcUpdated = (model): void => {
    const ds = (model.entities || []).find(ds => ds.schema_name === this.props.schema_name) || {};

    this.setState({
      id: ds.id,
      loading: model.loading,
      error: model.error,
      is_visible: ds.is_visible,
    });
  }

  private _onDoubleClick = () => this.setState({editSchemaName: true});
  private _onBlur = (): void => this.setState({
    editSchemaName: false,
    schemaNameValue: this.props.schema_name,
    schemaError: '',
  })

  private _onChange = (event): void => {
    const schemaNameValue = event.target.value;
    const regexp = /^ds_\w+$/;
    if (!schemaNameValue) return this.setState({schemaNameValue, schemaError: lang('validator_required')});
    const schemaError = regexp.test(schemaNameValue) ? '' : lang('schema_name_validator');
    this.setState({schemaNameValue, schemaError});
  }

  private _onClick = (event): void => {
    event.stopPropagation();
    const {loading, error, is_visible, id} = this.state;

    if (loading || error || !id) return;

    const authenticationModel = AuthenticationService.getInstance().getModel();
    if (authenticationModel.access_level === 'admin') {
      this._datasetService.updateOne(id, {is_visible: is_visible ? 0 : 1});
    }
  }

  private _onKeyUp = (event): void => {
    const {loading, error, id, schemaNameValue, schemaError} = this.state;
    const isSameValue = schemaNameValue === this.props.schema_name;
    if (event.key === 'Escape' || (event.key === 'Enter' && isSameValue)) return this._onBlur();
    if (event.key !== 'Enter' || (event.key === 'Enter' && schemaError)) return;
    if (loading || error || !id) return this.setState({schemaError: lang('error')});

    this._datasetService.updateOne(id, {schema_name: schemaNameValue})
      .catch(err => { this.setState({schemaError: err?.response?.data?.message || lang('error')}); });
  }

  public render() {
    const {loading, error, is_visible, editSchemaName, schemaNameValue, schemaError} = this.state;
    let hint = '';
    let status = '';

    if (loading) status = 'loading';
    else if (error) {
      status = 'error';
      hint = lang('dataset_list_status_error');
    } else if (!is_visible) {
      status = 'inactive';
      hint = lang('dataset_list_status_inactive');
    } else {
      status = 'active';
      hint = lang('dataset_list_status_active');
    }

    return (
      <>
        <div className="DatasetCard__ShemaContainer">
          <div className={cn('DatasetCard__ShemaName', status)}
               title={schemaNameValue}
               onDoubleClick={this._onDoubleClick}>{schemaNameValue}</div>
          {
            editSchemaName &&
            <>
              {schemaError && <span className="DatasetCard__ShemaError">{schemaError}</span>}
              <input type="text"
                     value={schemaNameValue}
                     className={cn('needsToChangeGeneralInputStyles DatasetCard__ShemaInput', {error: !!schemaError})}
                     onBlur={this._onBlur}
                     onChange={this._onChange}
                     onKeyUp={this._onKeyUp}
                     autoFocus={true}/>
            </>
          }
        </div>
        <BIIcon icon="donut" hint={hint} className={cn('DatasetCard__Indicator', status)} onPress={this._onClick}/>
      </>
    );
  }
}


export const DatasetCardLoadingContents: React.FC<any> = (): any => (
  <div className="DatasetCard__Inner">
    <div className="DatasetCard__Image loading">
    </div>
    <div className="DatasetCard__Content">
      <h1 className="DatasetCard__Title"></h1>
      <h2 className="DatasetCard__Description"></h2>
    </div>
  </div>
);
