import {BIIcon} from '../components/BIIcon/BIIcon';
import React from 'react';
import {IRootVM} from '../../view-controllers/Root/RootVC';
import {IRootSegment} from '../../plugins/plugins-model';
import {IRawSummary} from '../../services/SummaryService';
import {lang} from '../../utils/utils';

const RootContentError: React.FC<{ error: string }> = ({error}): JSX.Element => (
  <section className="RootContent RootContent--Error error"
           style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
    <BIIcon icon="bug" style={{width: 128, height: 128}} className="black"/>
    <span style={{fontSize: 20, padding: 24, textAlign: 'center'}}>{error}</span>
  </section>);


const RootContentLoading: React.FC<any> = (): JSX.Element => (
  <section className="RootContent RootContent--Loading loading">
    <img className="main-loading-image" src="assets/logo/logo-animated.svg"/>
  </section>);

export class RootContent extends React.Component<IRootVM, any> {
  public constructor(props) {
    super(props);
  }

  public render() {
    const {activeTab, tabs, activeTabIndex, summary, loading, error} = this.props;

    if (error) return <RootContentError error={error}/>;
    if (loading) return <RootContentLoading/>;

    return (
      <section className="RootContent">
        {activeTab === null && <RootTabss tabs={tabs} activeTabIndex={activeTabIndex} summary={summary?.data}/>}
        {activeTab !== null && React.createElement(activeTab.bodyElementClassCached, null)}
      </section>
    );
  }
}


interface IRootTabsProps {
  tabs: IRootSegment[];
  activeTabIndex: number;
  summary: IRawSummary;
}
// todo delete old RootTabs
const RootTabss: React.FC<IRootTabsProps> = ({tabs = [], activeTabIndex, summary}): JSX.Element => {
  return (
    <div className="RootContent__Tabs">
      {tabs.map((tab) => (
        <a href={tab.url} className="RootContent__Tabs--Item" key={tab.key}>
          <div className="RootContent__Tabs--Images"
               style={{backgroundImage: tab.bgUrl ? `url('${tab.bgUrl}')` : 'none'}}></div>
          <div className="RootContent__Tabs--Title">{tab.title}</div>
          <div className="RootContent__Tabs--Count">
            <span>{lang('available')}:</span> <span>{summary?.[tab?.key]?.count ?? null}</span>
          </div>
        </a>
      ))}
    </div>
  );
};
