import React from 'react';

import {DatasetsByTopicsService, ILocalDataset, ITopic} from '../../../srx/services/DatasetsByTopicsService';
import {coloring, lang, search as searchFN} from '../../utils/utils';
import {DatasetCard, DatasetCardLoadingContents} from '../../../srx/views/dataset/dataset/DatasetCard/DatasetCard';
import {OnlyWhenVisible} from '../../../srx/components/OnlyWhenVisible/OnlyWhenVisible';
import {ISearchVM, SearchVC} from '../../view-controllers/SearchVC';
import EditModeVC, {IEditModeModel} from '../../view-controllers/EditModeVC';
import {AlertsVC} from '../../view-controllers/AlertsVC';
import SVGIcon from '../components/SVGIcon';
import cn from 'classnames';
import {extractErrorMessage, IUrl, UrlState} from '@luxms/bi-core';
import {$eid} from '@luxms/bi-core/dist/utils/list';


interface IDatasetsListViewState {
  readonly loading: boolean;
  readonly error: string;
  readonly topicId: number;
  readonly datasets: ILocalDataset [];
  readonly search: string;
  readonly edit: boolean;
}

export class DatasetsListView1 extends React.Component<any, IDatasetsListViewState> {
  private readonly _urlState: UrlState;
  private readonly _searchVC: SearchVC;
  private readonly _editModeVC: EditModeVC;
  private readonly _datasetsByTopicsService: DatasetsByTopicsService;

  public state: IDatasetsListViewState;

  public constructor(props) {
    super(props);
    this._datasetsByTopicsService = new DatasetsByTopicsService();
    this._urlState = UrlState.getInstance();
    this._searchVC = SearchVC.getInstance();
    this._editModeVC = EditModeVC.getInstance();

    this.state = {loading: false, error: null, topicId: null, datasets: [], search: null, edit: false};
  }

  public componentDidMount() {
    this._urlState.subscribeUpdatesAndNotify(this._onUrlStateUpdated); // url srv
    this._searchVC.subscribeUpdatesAndNotify(this._onSearchUpdate);  // search srv
    this._editModeVC.subscribeUpdatesAndNotify(this._onEditUpdate); // edit mode
    this._datasetsByTopicsService.subscribeUpdatesAndNotify(this._onTopicsUpdate);
  }

  public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IDatasetsListViewState>) {
    if (prevState.search !== this.state.search) this._onTopicsUpdate();
  }

  public componentWillUnmount() {
    this._urlState.unsubscribe(this._onUrlStateUpdated);
    this._searchVC.unsubscribe(this._onSearchUpdate);
    this._editModeVC.unsubscribe(this._onEditUpdate);
    this._datasetsByTopicsService.unsubscribe(this._onTopicsUpdate);
  }

  // update fn
  private _onSearchUpdate = (model: ISearchVM): void => {
    if (model.loading || model.error) return;
    this.setState({search: model.search});
  }
  private _onUrlStateUpdated = (model: IUrl): void => {
    const {segment, segmentId} = model;
    let topicId = null;
    if (segment === 'ds' && (!segmentId || segmentId[0] === '@')) {
      topicId = segmentId ? Number(segmentId.slice(2)) : null;
    }
    this.setState({topicId}, () => this._onTopicsUpdate());
  }
  private _onTopicsUpdate = (): void => {
    const model = this._datasetsByTopicsService.getModel();
    this.setState({loading: !!(model.loading)});
    if (model.loading || model.error) return;
    const {topics} = model;
    const {topicId, search} = this.state;

    let datasets: any[] = [];

    if (search) {
      (model.datasets || []).forEach((d) => {
        if (searchFN(d.title, search)) datasets.push(d);
      });
    } else if (topicId !== null) datasets = $eid(topics, topicId)?.datasets ?? [];
    else {
      const noGroupsDatasets: any[] = $eid(topics, -1)?.datasets ?? [];
      const allTopics = topics.filter(t => t?.id !== -1);
      datasets = allTopics.concat(noGroupsDatasets);
    }

    this.setState({datasets});
  }
  private _onEditUpdate = (model: IEditModeModel): void => {
    if (model.loading || model.error) return;
    this.setState({edit: model.editMode});
  }

  // todo ничего умней не придумал
  private _onClick = async (key: string, d: any): Promise<void> => {
    if (key === 'edit') {
      const href = d.href;
      const a: HTMLAnchorElement = document.createElement('a');
      a.setAttribute('href', href);
      a.click();
      a.remove();
    }
    if (key === 'delete') {
      try {
        await this._datasetsByTopicsService.removeDataset(d.id, d.schema_name, d.guid);
        AlertsVC.getInstance().pushSuccessAlert(lang('information_delete'));
      } catch (error) {
        AlertsVC.getInstance().pushDangerAlert(extractErrorMessage(error));
      }
    }
  }

  // D&D
  private _renderCard = (dataset: any): JSX.Element => {
    const {edit} = this.state;
    if (!dataset?.schema_name) return <DatasetTopic key={dataset.id} topic={dataset} edit={edit}/>;
    return (
      <OnlyWhenVisible className="DatasetCard loading" fallback={() => <DatasetCardLoadingContents/>}
                       key={dataset.schema_name}>
        <DatasetCard
          key={dataset.schema_name}
          draggable={false}
          edit={edit}
          schema_name={dataset.schema_name}
          onClickAction={(key: string) => this._onClick(key, dataset)}/>
      </OnlyWhenVisible>
    );
  }

  public render() {
    const {datasets, edit, search, loading} = this.state;
    const data = datasets.filter((d) => (d?.is_visible !== 0 && !edit) || edit);
    const noData = !loading && !data.length && !search;

    return (
      <section className={cn('DatasetsListView', {noData})}>
        {data.map(d => this._renderCard(d))}
        {noData && <NoDataDataset/>}
      </section>
    );
  }
}

const DatasetTopic: React.FC<{ topic: ITopic, edit: boolean }> = ({topic, edit}): JSX.Element => {
  const count = (topic?.datasets || []).filter((d) => (d?.is_visible !== 0 && !edit) || edit).length;
  const color = topic?.config?.stateColor ?? null;
  if (count === 0 && !edit) return null;
  return (
    <div className="TopicCard">
      <a href={'#/ds/@' + '_' + topic.id}>
        <div className="TopicCard-Top"><DatasetsListTopicIcon fill={color}/></div>
        <div className="TopicCard-Center" style={{backgroundColor: color}}/>
        <div className="TopicCard-Content" style={{backgroundColor: color}}>
          <div className="TopicCard-Content-Title">{topic.title}</div>
          <div className="TopicCard-Content-Count">
            <span>{lang('available')}:</span> <span>{count}</span>
          </div>
        </div>
      </a>
    </div>
  );
};
const DatasetsListTopicIcon: React.FC<{ fill: string }> = ({fill}): JSX.Element => {
  return (
    <svg width="64" height="21" viewBox="0 0 64 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M64 11.9931C60.9046 11.8647 58.148 9.95517 56.9487 7.07692L56.0513 4.92308C54.8091 1.9419 51.8963 0 48.6667 0H8C3.58172 0 0 3.58172 0 8V21L0.551898 20.2517C2.05993 18.2069 4.44959 17 6.99032 17H64V11.9931Z"
        fill={fill || '#8284AC'}/>
    </svg>
  );
};

const NoDataDataset: React.FC<any> = (): JSX.Element => {
  return (
    <div className="DatasetsListView__NoData-Wrapper">
      <h3>Здесь пока ничего нет</h3>
      <div className="DatasetsListView__NoData__Cat">
        <SVGIcon path={require('../../../assets/icons/cat-in-box.svg')}/>
        <div className="DatasetsListView__NoData__Cat-Text">
          <p>Обратитесь к администратору,</p>
          <p>чтобы создать свой датасет</p>
        </div>
      </div>
    </div>
  );
};